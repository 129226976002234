import React from 'react';
import { makeStyles } from '@mui/styles';
import { useLanguage, useInstagramFeed } from '../../hooks';
import { Grid, Typography } from '@mui/material';
import { Loader, InstragramItem, ProgressButton } from '..';

const useStyles = makeStyles(theme => ({
    instagramFeed: {
        padding: 20,
        width: '100%',
        display: 'grid',
        gridAutoFlow: 'row',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
        gridAutoRows: 'max-content',
        justifyItems: 'center',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr 1fr 1fr'
        }
    },
    title: {
        width: '100%',
        padding: '50px 0px 30px'
    }
}));

function InstagramFeed({ token }) {
    const { i18n } = useLanguage();

    const { data, isError, hasMore, isLoading, onFetchMore } =
        useInstagramFeed(token);

    const classes = useStyles();

    if (isLoading && !data.length) {
        return <Loader />;
    }

    if (isError) {
        return (
            <Typography align="center" color="red" className={classes.title}>
                {i18n.instagram.error}
            </Typography>
        );
    }

    return (
        <Grid container>
            <Typography align="center" variant="h4" className={classes.title}>
                {i18n.instagram.title}
            </Typography>
            <Grid item xs={12} className={classes.instagramFeed}>
                {isLoading && <Loader />}
                {data &&
                    data.map(feed => (
                        <InstragramItem
                            key={feed.id}
                            caption={feed.caption}
                            mediaType={feed.media_type}
                            link={feed.permalink}
                            src={feed.media_url}
                        />
                    ))}
            </Grid>
            {hasMore && (
                <Grid container item xs={12} justifyContent="center">
                    <ProgressButton onClick={onFetchMore} isLoading={isLoading}>
                        {i18n.instagram.getMore}
                    </ProgressButton>
                </Grid>
            )}
        </Grid>
    );
}

export default React.memo(InstagramFeed);