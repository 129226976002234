import React from 'react';
import {
    ConfirmDialog,
    SpeakerDialog,
    SermonDialog,
    BroadcastDialog,
    PlaylistDialog
} from '../admin';

export const AdminDialogsContext = React.createContext();

export const AdminDialogsProvider = ({ children }) => {
    const sermonDialogRef = React.useRef();
    const speakerDialogRef = React.useRef();
    const confirmDialofRef = React.useRef();
    const broadcastDialogRef = React.useRef();
    const playlistDialogRef = React.useRef();

    const openSermonDialog = React.useCallback(values => {
        sermonDialogRef.current.openDialog(values);
    }, []);

    const openSpeakerDialog = React.useCallback(values => {
        speakerDialogRef.current.openDialog(values);
    }, []);

    const openConfirmDialog = React.useCallback((action, queryKey, id) => {
        confirmDialofRef.current.openDialog(action, queryKey, id);
    }, []);

    const openBroadcastDialog = React.useCallback(values => {
        broadcastDialogRef.current.openDialog(values);
    }, []);

    const openPlaylistDialog = React.useCallback(values => {
        playlistDialogRef.current.openDialog(values);
    }, []);

    const values = React.useMemo(() => {
        return {
            openSermonDialog,
            openSpeakerDialog,
            openConfirmDialog,
            openBroadcastDialog,
            openPlaylistDialog
        };
    }, [
        openSermonDialog,
        openSpeakerDialog,
        openConfirmDialog,
        openBroadcastDialog,
        openPlaylistDialog
    ]);

    return (
        <AdminDialogsContext.Provider value={values}>
            {children}
            <SermonDialog ref={sermonDialogRef} />
            <SpeakerDialog ref={speakerDialogRef} />
            <ConfirmDialog ref={confirmDialofRef} />
            <BroadcastDialog ref={broadcastDialogRef} />
            <PlaylistDialog ref={playlistDialogRef} />
        </AdminDialogsContext.Provider>
    );
};
