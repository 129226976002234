import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReactPlayer from 'react-player';
import { NotificationsActive as LiveIcon } from '@mui/icons-material';
import { Broadcast, Playlist } from '../firebase';
import Loader from './Loader';
import { getDay } from '../utils';
import { useLanguage, useAlert } from '../hooks';

const useStyles = makeStyles(theme => ({
    '@keyframes pulse': {
        '0%': {
            transform: 'scale(0.95)',
            boxShadow: '0 0 0 0 rgba(255, 0, 0, 1)'
        },

        '70%': {
            transform: 'scale(1)',
            boxShadow: '0 0 0 10px rgba(0, 0, 0, 0)'
        },
        '100%': {
            transform: 'scale(0.95)',
            boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)'
        }
    },
    videoContainer: {
        width: '100%',
        flex: 1,
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            height: 400
        }
    },
    liveTextContainer: {
        paddingBottom: 20
    },
    liveIcon: {
        borderRadius: '50%',
        boxShadow: '0 0 0 0 rgba(0, 0, 0, 1)',
        transform: 'scale(1)',
        animation: '$pulse 2s infinite',
        color: theme.palette.colors.red,
        fontSize: '30px !important'
    },
    liveText: {
        paddingLeft: 10,
        color: theme.palette.colors.red
    }
}));

export default React.memo(function PageVideoPLayer() {
    const [videoInfo, setVideoInfo] = React.useState({ videoUrl: '' });
    const [isLive, setIsLive] = React.useState(false);
    const { openAlert } = useAlert()
    const { i18n } = useLanguage();

    const getVideoUrl = React.useCallback(async () => {
        try {
            const [broadcast] = await new Broadcast()
            .actions()
            .getDocumentsForClientWithoutLang();

        if (broadcast) {
            setVideoInfo(broadcast);
            setIsLive(true);
            return;
        }

        const playlist = await new Playlist()
            .actions()
            .queryByFilters({ dayOfWeek: getDay() });

        setVideoInfo({
            videoUrl: playlist[0].videoUrl
        });
        }catch(error) {
            openAlert(error.name, error.message, true);
        }
    }, [openAlert]);

    React.useEffect(() => {
        getVideoUrl();
    }, [getVideoUrl]);

    const classes = useStyles();

    if (!videoInfo.videoUrl) {
        return (
            <Box className={classes.videoContainer}>
                <Loader />
            </Box>
        );
    }

    return (
        <Box className={classes.videoContainer}>
            {isLive && (
                <Box display="flex" className={classes.liveTextContainer}>
                    <LiveIcon className={classes.liveIcon} />
                    <Typography variant="h5" className={classes.liveText}>
                        {i18n.homePage.video.live}
                    </Typography>
                </Box>
            )}
            <ReactPlayer
                url={videoInfo.videoUrl}
                controls={true}
                width="100%"
                height="100%"
            />
        </Box>
    );
});
