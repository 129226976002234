import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import {
    AccordionComponent,
    CustomLink,
    PageVideoPLayer,
    SermonsCarousel
} from '../../components/generic';
import { useLanguage } from '../../components/hooks';
import MainLayout from '../../components/layouts/MainLayout';
import links from '../../links';

const useStyles = makeStyles(theme => ({
    videoPlayerContainer: {
        maxHeight: '600px',
        height: 600,
        padding: '0px 25px 25px 25px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '100px !important',
            height: 400
        }
    },
    title: {
        maxWidth: 350,
        padding: '50px 0px',
        margin: 'auto !important',
        [theme.breakpoints.down('md')]: {
            padding: '35px 0px'
        }
    },
    row: {
        padding: '0px 25px 25px 25px',
        [theme.breakpoints.down('md')]: {
            paddingTop: 100
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: 25
        }
    },
    container: {
        paddingTop: 50
    },
    text: {
        paddingTop: 20
    },
    link: {
        paddingLeft: 5,
        color: `${theme.palette.colors.darkGreen00} !important`
    }
}));

export default function HomePage() {
    const { i18n } = useLanguage();
    const classes = useStyles();

    return (
        <MainLayout>
            <Typography variant="h4" align="center" className={classes.title}>
                Power Of God
            </Typography>
            <Grid container>
                <Grid
                    container
                    item
                    xs={12}
                    md={9}
                    className={classes.videoPlayerContainer}
                >
                    <PageVideoPLayer />
                </Grid>
                <Grid item xs={12} md={3} className={classes.row}>
                    <AccordionComponent
                        isOpen={true}
                        title={i18n.homePage.servicesProgramm.title}
                    >
                        <Typography variant="subtitle1">
                            {i18n.homePage.servicesProgramm.monday} 20:15 -
                            21:00
                        </Typography>
                        <Typography variant="subtitle1">
                            {i18n.homePage.servicesProgramm.wednesday} 20:15 -
                            21:00
                        </Typography>
                        <Typography variant="subtitle1">
                            {i18n.homePage.servicesProgramm.friday} 20:15 -
                            21:00
                        </Typography>
                        <Typography variant="subtitle1">
                            {i18n.homePage.servicesProgramm.sunday} 11:00 -
                            12:00
                        </Typography>
                        <Typography variant="caption" className={classes.text}>
                            {i18n.homePage.accordionInfo.text}
                            <CustomLink
                                customClass={classes.link}
                                to={links.churchService}
                            >
                                {i18n.homePage.accordionInfo.link}
                            </CustomLink>
                        </Typography>
                    </AccordionComponent>
                </Grid>
                <Grid item xs={12} className={classes.container}>
                    <SermonsCarousel />
                </Grid>
            </Grid>
        </MainLayout>
    );
}
