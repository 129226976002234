import { Search as SearchIcon } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useLanguage } from '../hooks';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    icon: {
        margin: '50px auto',
        fontSize: '80px !important',
        color: `${theme.palette.warning.main} !important`
    },
    text: {
        width: '100%'
    }
}));

export default function NoContentAvailable() {
    const { i18n } = useLanguage();

    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <SearchIcon className={classes.icon} />
            <Typography varaint="h5" className={classes.text} align="center">
                {i18n.sermonsPage.emptyContent}
            </Typography>
        </Box>
    );
}
