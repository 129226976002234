import { Logout as LogoutIcon, Menu as MenuIcon } from '@mui/icons-material';
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useAuth, useLanguage } from '../hooks';
import { DropDownMenu, LanguageSwitch } from '../navigationBar';
import { DRAWERWIDTH } from './constant';

const useStyles = makeStyles(theme => ({
    toolBar: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: `${theme.palette.colors.darkGreen00}`
    },
    iconButton: {
        marginRight: 36,
        color: `${theme.palette.colors.lightGrey} !important`,
        display: ({ isOpen }) => isOpen && 'none'
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: 250
    },
    dropdownIconButtons: {
        color: theme.palette.colors.white,
        padding: 0,
        margin: 0
    }
}));

const StyledAppBar = styled(AppBar, {
    shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
        marginLeft: DRAWERWIDTH,
        width: `calc(100% - ${DRAWERWIDTH}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    })
}));

export default function AdminAppBar({ isOpen, open }) {
    const { signOutUser } = useAuth();
    const { i18n } = useLanguage();

    const classes = useStyles({ isOpen });

    return (
        <StyledAppBar position="fixed" open={isOpen}>
            <Toolbar classes={{ root: classes.toolBar }}>
                <IconButton
                    onClick={open}
                    edge="start"
                    classes={{ root: classes.iconButton }}
                >
                    <MenuIcon />
                </IconButton>
                <Box className={classes.actionContainer}>
                    <DropDownMenu
                        id="admin"
                        title={i18n.adminPage.menu.adminActions}
                    >
                        <IconButton
                            onClick={signOutUser}
                            classes={{ root: classes.dropdownIconButtons }}
                        >
                            <LogoutIcon size="small" />
                            <Typography variant="body1">
                                {i18n.adminPage.menu.appBar.logout}
                            </Typography>
                        </IconButton>
                    </DropDownMenu>
                    <LanguageSwitch />
                </Box>
            </Toolbar>
        </StyledAppBar>
    );
}
