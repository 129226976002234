import React from 'react';
import { useAdminDialogs, useLanguage } from '../hooks';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    button: {
        display: 'flex',
        color: theme.palette.primary.main
    },
    icon: {
        color: theme.palette.primary.main
    },
    text: {
        paddingRight: 10
    }
}));

export default function DataGridDeleteAction({ action, queryKey, id }) {
    const { openConfirmDialog } = useAdminDialogs();
    const { i18n } = useLanguage();

    const onDelete = React.useCallback(() => {
        openConfirmDialog(action, queryKey, id);
    }, [openConfirmDialog, action, queryKey, id]);

    const classes = useStyles();

    return (
        <IconButton className={classes.button} onClick={onDelete}>
            <DeleteIcon className={classes.icon} />
            <Typography variant="body1" className={classes.text}>
                {i18n.adminPage.itemActions.delete}
            </Typography>
        </IconButton>
    );
}
