import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import {
    AdminDialogsProvider,
    AlertProvider,
    AuthenticationProvider,
    LanguageProvider,
    VideoPlayerProvider,
    HelmetProvider
} from './components/contexts';
import { ScrollToTop } from 'react-router-scroll-to-top';
import { ErrorBoundaryComponent } from './components/generic';
import reportWebVitals from './reportWebVitals';
import theme from './theme';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false
        }
    }
});

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <LanguageProvider>
                <QueryClientProvider client={queryClient}>
                    <ErrorBoundaryComponent>
                        <AuthenticationProvider>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <AlertProvider>
                                    <VideoPlayerProvider>
                                        <AdminDialogsProvider>
                                            <Router>
                                                <ScrollToTop />
                                                <HelmetProvider>
                                                    <App />
                                                </HelmetProvider>
                                            </Router>
                                        </AdminDialogsProvider>
                                    </VideoPlayerProvider>
                                </AlertProvider>
                            </LocalizationProvider>
                        </AuthenticationProvider>
                    </ErrorBoundaryComponent>
                </QueryClientProvider>
            </LanguageProvider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
