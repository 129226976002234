import { Menu as MenuIcon } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import links from '../../links';
import { CustomLink, Logo } from '../generic';
import { useLanguage } from '../hooks';
import { DropDownMenu, LanguageSwitch, MobileDrawer } from '../navigationBar';

const useStyles = makeStyles(theme => ({
    icon: {
        color: theme.palette.colors.white
    },
    menuItemColor: {
        color: theme.palette.primary.main,
        textDecoration: 'none !important'
    },
    titleContainer: {
        margin: '30px auto'
    },
    title: {
        color: theme.palette.primary.main,
        marginBottom: '50px !important'
    },
    logoContainer: {
        margin: 'auto',
        width: 100,
        height: 100
    },
    linksContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: '100%',
        maxHeight: 600,
        flex: 1,
        maxWidth: 800
    },
    links: {
        color: `${theme.palette.primary.main} !important`,
        textDecoration: 'none !important',
        '& :hover': {
            background: `${theme.palette.colors.white} !important`
        }
    }
}));

export default function MobileLinks() {
    const mobileDrawerRef = React.useRef();
    const { i18n } = useLanguage();

    const openDrawer = React.useCallback(() => {
        mobileDrawerRef.current.open();
    }, []);

    const classes = useStyles();

    return (
        <>
            <IconButton className={classes.icon} onClick={openDrawer}>
                <MenuIcon className={classes.icon} />
            </IconButton>
            <MobileDrawer ref={mobileDrawerRef}>
                <Box className={classes.root}>
                    <Box className={classes.titleContainer}>
                        <Typography
                            variant="h5"
                            align="center"
                            className={classes.title}
                        >
                            {i18n.navigation.mobileTitle}
                        </Typography>
                        <Logo customContainerClass={classes.logoContainer} />
                    </Box>

                    <Box className={classes.linksContainer}>
                        <CustomLink to={links.home} customClass={classes.links}>
                            {i18n.navigation.homepage}
                        </CustomLink>
                        <DropDownMenu
                            id={i18n.navigation.aboutUs}
                            title={i18n.navigation.aboutUs}
                        >
                            <CustomLink
                                to={links.ourHistory}
                                customClass={classes.links}
                            >
                                {i18n.navigation.aboutUsSublinks.ourHistory}
                            </CustomLink>
                            <CustomLink
                                to={links.whatWeBelieve}
                                customClass={classes.links}
                            >
                                {i18n.navigation.aboutUsSublinks.whatWeBelieve}
                            </CustomLink>
                        </DropDownMenu>
                        <DropDownMenu
                            id={i18n.navigation.church}
                            title={i18n.navigation.church}
                        >
                            <CustomLink
                                to={links.churchService}
                                customClass={classes.links}
                            >
                                {i18n.navigation.churchSublinks.churchService}
                            </CustomLink>
                            <CustomLink
                                to={links.youth}
                                customClass={classes.links}
                            >
                                {i18n.navigation.churchSublinks.youth}
                            </CustomLink>
                        </DropDownMenu>

                        <CustomLink
                            to={links.sermons}
                            customClass={classes.links}
                        >
                            {i18n.navigation.sermons}
                        </CustomLink>
                        <CustomLink
                            to={links.contact}
                            customClass={classes.links}
                        >
                            {i18n.navigation.contact}
                        </CustomLink>
                        <LanguageSwitch />
                    </Box>
                </Box>
            </MobileDrawer>
        </>
    );
}
