import { DatabaseActions } from '../index';
import { PLAYLIST_COLLECTION, ORDER_BY_DATE } from './constants';
import { Timestamp } from '@firebase/firestore';

export default class Playlist {
    constructor(props) {
        this.data = props && props;
    }

    timestampFormatter = () => {
        const date = this.data.date;
        return (this.data.date = Timestamp.fromDate(date));
    };

    getState = () => {
        if (!this.data) {
            return null;
        }
        return {
            ...this.data,
            date: this.timestampFormatter()
        };
    };

    actions = () =>
        new DatabaseActions({
            data: this.getState(),
            collection: PLAYLIST_COLLECTION,
            order: ORDER_BY_DATE
        });
}
