import React from 'react';

const fetchInstagramFeed = async (url, setUrl, setFeed, setHasMore) => {
    try {
        const response = await fetch(url);
        const data = await response.json();
        if (!data.paging.next) {
            setHasMore(false);
            setFeed(prevData => ({
                data: [...prevData.data, ...data.data],
                isError: false,
                isLoading: false
            }));
            return;
        }
        setUrl(data.paging.next);
        setFeed(prevData => ({
            data: [...prevData.data, ...data.data],
            isError: false,
            isLoading: false
        }));
    } catch (error) {
        console.log(error);
        setFeed({ data: [], isError: true, isLoading: false });
    }
};

const fields = [
    'caption',
    'children{media_type,media_url,thumbnail_url}',
    'id',
    'media_type',
    'media_url',
    'permalink',
    'thumbnail_url',
    'timestamp'
].join(',');

export default function useInstagramFeed(token) {
    const [url, setUrl] = React.useState(
        `https://graph.instagram.com/me/media?fields=${fields}&&access_token=${token}&limit=10`
    );
    const [hasMore, setHasMore] = React.useState(true);
    const [feed, setFeed] = React.useState({
        data: [],
        isError: false,
        isLoading: true
    });

    const onFetchMore = React.useCallback(() => {
        fetchInstagramFeed(url, setUrl, setFeed, setHasMore);
    }, [url]);

    React.useEffect(() => {
        fetchInstagramFeed(url, setUrl, setFeed, setHasMore);
        // eslint-disable-next-line
    }, []);

    const values = React.useMemo(() => {
        return {
            ...feed,
            hasMore,
            onFetchMore
        };
    }, [feed, hasMore, onFetchMore]);

    return values;
}
