import { Drawer } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    paper: {
        backgroundColor: `${theme.palette.colors.drakGreen00} !important`,
        width: 250
    }
}));

const MobileDrawer = ({ children }, ref) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const open = React.useCallback(() => {
        setIsOpen(true);
    }, []);

    const close = React.useCallback(() => {
        setIsOpen(false);
    }, []);

    React.useImperativeHandle(ref, () => ({
        open
    }));

    const classes = useStyles();

    return (
        <Drawer
            classes={{ root: classes.root }}
            anchor="left"
            open={isOpen}
            onClose={close}
            PaperProps={{ className: classes.paper }}
        >
            {children}
        </Drawer>
    );
};

export default React.forwardRef(MobileDrawer);
