import { Box, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Playlist, PLAYLIST_QUERY_KEY } from '../../firebase';
import {
    Datepicker,
    GenericDialog,
    ProgressButton,
    TextFieldInput,
    OptionSelector
} from '../../generic';
import { useAlert, useLanguage, useValidation } from '../../hooks';

const defaultValues = {
    id: '',
    dayOfWeek: '',
    playlistName: '',
    videoUrl: '',
    date: new Date()
};

const useStyles = makeStyles(theme => ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: 400,
        justifyContent: 'space-evenly'
    },
    textField: {
        width: '100%'
    }
}));

const PlaylistDialog = ({ _ }, ref) => {
    const [playlistValues, setPlaylistValues] = React.useState(defaultValues);
    const genericDialogRef = React.useRef();
    const { i18n, lang } = useLanguage();
    const { openAlert } = useAlert();

    const { validateValues, ifError, errorMessages, resetValidation } =
        useValidation(['playlistName', 'videoUrl', 'date']);

    const queryClient = useQueryClient();

    const { isLoading, mutateAsync } = useMutation(
        () => {
            const { dayOfWeek, videoUrl, playlistName, date } = playlistValues;
            if (playlistValues.id) {
                return new Playlist({
                    dayOfWeek,
                    playlistName,
                    videoUrl,
                    lang,
                    date
                })
                    .actions()
                    .editDocument(playlistValues.id);
            }

            return new Playlist({
                dayOfWeek,
                playlistName,
                videoUrl,
                lang,
                date
            })
                .actions()
                .addDocument();
        },
        { onSuccess: () => queryClient.invalidateQueries(PLAYLIST_QUERY_KEY) }
    );

    const onChange = React.useCallback(
        (event, selectedValue, optionName) => {
            resetValidation();
            const { name, value } = event.target;

            if (optionName) {
                setPlaylistValues(prevValues => ({
                    ...prevValues,
                    [optionName]: selectedValue
                }));
                return;
            }

            setPlaylistValues(prevValues => ({
                ...prevValues,
                [name]: value
            }));
        },
        [resetValidation]
    );

    const openDialog = React.useCallback(() => {
        genericDialogRef.current.open();
    }, []);

    const closeDialog = React.useCallback(() => {
        resetValidation();
        setPlaylistValues(defaultValues);
        genericDialogRef.current.close();
    }, [resetValidation]);

    React.useImperativeHandle(ref, () => ({
        openDialog: values => {
            if (values) {
                setPlaylistValues({ ...values, date: new Date(values.date) });
            }
            openDialog();
        }
    }));

    const onSubmitValues = React.useCallback(async () => {
        const { dayOfWeek, playlistName, videoUrl, lang, date } =
            playlistValues;
        if (
            !validateValues({
                dayOfWeek,
                playlistName,
                videoUrl,
                lang,
                date
            })
        ) {
            return;
        }
        try {
            await mutateAsync();
            closeDialog();
        } catch (error) {
            openAlert(error.name, error.message, true);
        }
    }, [playlistValues, validateValues, mutateAsync, openAlert, closeDialog]);

    const classes = useStyles();

    return (
        <GenericDialog
            onClose={closeDialog}
            ref={genericDialogRef}
            submitButton={
                <ProgressButton isLoading={isLoading} onClick={onSubmitValues}>
                    {i18n.genericDialog.adminDialogs.submit}
                </ProgressButton>
            }
            title={
                playlistValues.id
                    ? i18n.genericDialog.adminDialogs.playlistEditTitle
                    : i18n.genericDialog.adminDialogs.playlistTitle
            }
        >
            <Box className={classes.formContainer}>
                <OptionSelector
                    TextFieldComponent={TextField}
                    name="dayOfWeek"
                    label={i18n.genericDialog.adminDialogs.dayOfWeek}
                    value={playlistValues.dayOfWeek}
                    onChange={onChange}
                    className={classes.textField}
                    error={ifError('dayOfWeek')}
                    helperText={errorMessages('dayOfWeek')}
                    langDataOptions={[
                        'Sunday',
                        'Monday',
                        'Tuesday',
                        'Wednesday',
                        'Thursday',
                        'Friday',
                        'Saturday'
                    ]}
                />
                <TextFieldInput
                    name="playlistName"
                    label={i18n.genericDialog.adminDialogs.playlistName}
                    value={playlistValues.playlistName}
                    onChange={onChange}
                    className={classes.textField}
                    error={ifError('playlistName')}
                    helperText={errorMessages('playlistName')}
                />
                <TextFieldInput
                    name="videoUrl"
                    label={i18n.genericDialog.adminDialogs.videoUrl}
                    value={playlistValues.videoUrl}
                    onChange={onChange}
                    className={classes.textField}
                    error={ifError('videoUrl')}
                    helperText={errorMessages('videoUrl')}
                />
                <Datepicker
                    name="date"
                    value={playlistValues.date}
                    label={i18n.genericDialog.adminDialogs.date}
                    setValues={setPlaylistValues}
                    className={classes.textField}
                    fullWidth
                    error={ifError('date')}
                    helperText={errorMessages('date')}
                />
            </Box>
        </GenericDialog>
    );
};

export default React.forwardRef(PlaylistDialog);
