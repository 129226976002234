import { makeStyles } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { AdminCheckbox, DataGridDeleteAction } from '..';
import { ContactInfo, CONTACT_INFO_QUERY_KEY } from '../../firebase';

const columns = [
    {
        field: 'email',
        headerName: 'Email',
        width: 200,
        sortable: true
    },
    {
        field: 'comments',
        headerName: 'Comments',
        flex: 1
    },
    {
        field: 'lang',
        headerName: 'Lang',
        width: 90,
        sortable: false
    },
    {
        field: 'date',
        headerName: 'Date',
        width: 120
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 140,
        renderCell: params => {
            return <AdminCheckbox data={params.row} />;
        }
    },
    {
        field: '',
        headerName: 'Actions',
        sortable: false,
        width: 100,
        disableClickEventBubbling: true,
        renderCell: params => {
            const onDelete = () => {
                return new ContactInfo()
                    .actions()
                    .deleteDocument(params.row.id);
            };

            return (
                <DataGridDeleteAction
                    id={params.row.id}
                    action={onDelete}
                    queryKey={CONTACT_INFO_QUERY_KEY}
                />
            );
        }
    }
];

const useStyles = makeStyles(theme => ({
    dataGridRoot: {
        marginTop: 20
    }
}));

export default function ContactDataGrid({ loading, data }) {
    const [pageSize, setPageSize] = React.useState(5);
    const classes = useStyles();

    const rows = React.useMemo(() => {
        if (!data) {
            return [];
        }
        return data;
    }, [data]);

    return (
        <DataGrid
            classes={{ root: classes.dataGridRoot }}
            columns={columns}
            rows={rows}
            loading={loading}
            autoHeight
            autoPageSize
            pageSize={pageSize}
            rowsPerPageOptions={[5, 10, 25]}
            onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        />
    );
}
