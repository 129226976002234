import { Box, CircularProgress, Typography } from '@mui/material';
import { useLanguage } from '../hooks';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: ({ customHeight }) => (customHeight ? '100vh' : '100%'),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    progress: {
        color: `${theme.palette.colors.darkGreen00} !important`
    }
}));

export default function Loader({ customHeight }) {
    const { i18n } = useLanguage();
    const classes = useStyles({ customHeight });
    return (
        <Box className={classes.root}>
            <Typography variant={customHeight ? 'h3' : 'h6'} gutterBottom>
                {i18n.loader}
            </Typography>
            <CircularProgress className={classes.progress} />
        </Box>
    );
}
