import React from 'react';

export default function useEqualHeight(ref) {
    const [height, setHeight] = React.useState();

    React.useEffect(() => {
        if (ref.current) {
            setHeight(ref.current.offsetHeight);
        }
    }, [ref]);

    return height;
}
