import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Map as MapIcon } from '@mui/icons-material';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { Loader } from '../generic';
import { useLanguage } from '../hooks';

const center = {
    lat: 37.8948109,
    lng: 23.7616627
};

const position = {
    lat: 37.8948109,
    lng: 23.7616627
};

const key = require('../../env.json').googleMaps.key;

const useStyles = makeStyles(theme => ({
    box: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: ({ height }) => (height ? height : '100%'),
        border: `1px solid ${theme.palette.error.main}`
    },
    icon: { fontSize: '60px !important' },
    text: {
        paddingTop: 30
    }
}));

function GoogleMapView({ height }) {
    const { isLoaded, loadError } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: key
    });
    const { i18n } = useLanguage();

    const classes = useStyles({ height: height });

    if (!isLoaded) {
        return <Loader />;
    }

    if (loadError) {
        return (
            <Box className={classes.box}>
                <MapIcon className={classes.icon} color="error" />
                <Typography variant="h6" className={classes.text} color="error">
                    {i18n.map.error}
                </Typography>
            </Box>
        );
    }

    return (
        <GoogleMap
            mapContainerStyle={{ height: height }}
            center={center}
            zoom={17}
        >
            <Marker position={position} />
        </GoogleMap>
    );
}

export default React.memo(GoogleMapView);
