import {
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon
} from '@mui/icons-material';
import { Divider, Drawer, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/styles';
import React from 'react';
import { DRAWERWIDTH } from './constant';
import DrawerHeader from './DrawerHeader';

const openedMixin = theme => ({
    width: DRAWERWIDTH,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
});

const closedMixin = theme => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`
    }
});

const StyledDrawer = styled(Drawer, {
    shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
    width: DRAWERWIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme)
    })
}));

export default function AdminDrawer({ isOpen, close, children }) {
    const theme = useTheme();

    const renderChevron = React.useMemo(() => {
        return theme.direction === 'rtl' ? (
            <ChevronRightIcon />
        ) : (
            <ChevronLeftIcon />
        );
    }, [theme.direction]);

    return (
        <StyledDrawer variant="permanent" open={isOpen}>
            <DrawerHeader>
                <IconButton onClick={close}>{renderChevron}</IconButton>
            </DrawerHeader>
            <Divider />
            {children}
        </StyledDrawer>
    );
}
