import React from 'react';
import { useLanguage } from '../../components/hooks';
import { Box, Typography } from '@mui/material/';
import { makeStyles } from '@mui/styles';
import { CustomLink } from '../../components/generic/';
import { useLocation } from 'react-router-dom';
import { Logo } from '../../components/generic';
import { LanguageSwitch } from '../../components/navigationBar/';

const useStyles = makeStyles(theme => ({
    homeButton: {
        color: theme.palette.colors.darkGreen00
    },
    switcher: {
        position: 'absolute',
        top: 20,
        left: 40
    }
}));

export default function NotFoundPage() {
    const { i18n } = useLanguage();
    const classes = useStyles();
    let location = useLocation();

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100vh"
        >
            <LanguageSwitch customClass={classes.switcher} />
            <Logo />
            <Box marginTop={2}>
                <Typography variant="h4" gutterBottom align="center">
                    {i18n.notFoundPage.title}
                </Typography>

                <Typography variant="body1" gutterBottom align="center">
                    {i18n.notFoundPage.text} <code>{location.pathname}</code>
                </Typography>
            </Box>
            <Box marginTop={2}>
                <CustomLink to="/" customClass={classes.homeButton}>
                    {i18n.notFoundPage.link}
                </CustomLink>
            </Box>
        </Box>
    );
}
