const SERMONS_COLLECTION = 'sermons';
const SERMONS_QUERY_KEY = 'sermons';
const SPEAKERS_COLLECTIONS = 'speakers';
const SPEAKERS_QUERY_KEY = 'speakers';
const CONTACT_INFO_COLLECTION = 'contactInfo';
const CONTACT_INFO_QUERY_KEY = 'contactInfo';
const BROADCAST_QUERY_KEY = 'broadcast';
const BROADCAST_COLLECTION = 'broadcast';
const PLAYLIST_QUERY_KEY = 'playlist';
const PLAYLIST_COLLECTION = 'playlist';

const ORDER_BY_LASTNAME = 'lastname';
const ORDER_BY_DATE = 'date';
const DESC = 'desc';

export {
    DESC,
    PLAYLIST_QUERY_KEY,
    PLAYLIST_COLLECTION,
    BROADCAST_QUERY_KEY,
    BROADCAST_COLLECTION,
    ORDER_BY_DATE,
    SERMONS_COLLECTION,
    SERMONS_QUERY_KEY,
    SPEAKERS_COLLECTIONS,
    SPEAKERS_QUERY_KEY,
    CONTACT_INFO_COLLECTION,
    CONTACT_INFO_QUERY_KEY,
    ORDER_BY_LASTNAME
};
