import React from 'react';
import NorifyUserAlert from '../generic/NotifyUserAlert';

export const AlertContext = React.createContext();

export const AlertProvider = ({ children }) => {
    const notifyUserRef = React.useRef();

    const openAlert = React.useCallback((title, context, isError) => {
        notifyUserRef.current.open(title, context, isError);
    }, []);

    return (
        <AlertContext.Provider value={{ openAlert }}>
            {children}
            <NorifyUserAlert ref={notifyUserRef} />
        </AlertContext.Provider>
    );
};
