import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { TextField } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%'
    },
    labelFocused: {
        color: `${theme.palette.colors.darkGreen00} !important`
    }
}));

export default function Datepicker({
    name,
    fullWidth,
    containerClass,
    setValues,
    error,
    helperText,
    ...props
}) {
    const onChange = newValue => {
        setValues(prevValues => ({
            ...prevValues,
            [name]: newValue
        }));
    };

    const classes = useStyles();

    return (
        <Box className={clsx(classes.container, containerClass)}>
            <DatePicker
                inputFormat="dd/MM/yyyy"
                onChange={onChange}
                clearable
                {...props}
                renderInput={params => (
                    <TextField
                        fullWidth={fullWidth}
                        name={name}
                        error={error}
                        helperText={helperText}
                        {...params}
                        InputLabelProps={{
                            classes: { focused: classes.labelFocused }
                        }}
                    />
                )}
            />
        </Box>
    );
}
