import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
    MenuBook as BookIcon,
    Today as DateIcon,
    SettingsVoice as SpeakerIcon,
    Feed as FeedIcon
} from '@mui/icons-material';
import React from 'react';
import { useVideoPlayer } from '../../hooks';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 250,
        margin: '10px',
        padding: '10px auto'
    },
    boxContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 10
    },
    icons: {
        marginRight: 10,
        color: `${theme.palette.colors.darkGreen00} !important`
    }
}));

function Book({ book, chapterAndVerce, page }) {
    const classes = useStyles();

    if (book === '-' || chapterAndVerce === '-' || page === '-') {
        return null;
    }

    return (
        <Box className={classes.boxContainer}>
            <BookIcon size="small" className={classes.icons} />
            <Typography variant="body2" color="text.secondary">
                {book} - {chapterAndVerce} - ({page})
            </Typography>
        </Box>
    );
}

export default function SermonCard({
    title,
    speakers,
    category,
    book,
    chapterAndVerce,
    link,
    thumbnail,
    page,
    date
}) {
    const { openVideoPlayer } = useVideoPlayer();

    const openVideoDialog = React.useCallback(() => {
        openVideoPlayer(title, link);
    }, [openVideoPlayer, title, link]);

    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardActionArea onClick={openVideoDialog}>
                <CardMedia
                    component="img"
                    height="200"
                    image={thumbnail}
                    alt={`${speakers}-${book}-img`}
                />
                <CardContent>
                    <Typography gutterBottom variant="body1" component="div">
                        {title}
                    </Typography>
                    <Box className={classes.boxContainer}>
                        <FeedIcon size="small" className={classes.icons} />
                        <Typography variant="body2" color="text.secondary">
                            {category}
                        </Typography>
                    </Box>
                    <Box className={classes.boxContainer}>
                        <SpeakerIcon size="small" className={classes.icons} />
                        <Typography variant="body2" color="text.secondary">
                            {speakers.join(', ')}
                        </Typography>
                    </Box>
                    <Book
                        book={book}
                        chapterAndVerce={chapterAndVerce}
                        page={page}
                    />
                    <Box className={classes.boxContainer}>
                        <DateIcon size="small" className={classes.icons} />
                        <Typography variant="body2" color="text.secondary">
                            {date}
                        </Typography>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
