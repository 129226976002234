import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import * as R from 'ramda';
import InfiniteScroll from 'react-infinite-scroll-component';
import { EmptyContent, Loader, Logo } from '../';
import { Sermon } from '../../firebase';
import { useLanguage, useAlert } from '../../hooks';
import SermonCard from './SermonCard';

const useStyles = makeStyles(theme => ({
    container: {
        display: ({ documents }) => (!R.isEmpty(documents) ? 'grid' : 'flex'),
        gridAutoFlow: 'row',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gridAutoRows: 'max-content',
        justifyItems: 'center',
        [theme.breakpoints.down('lg')]: {
            gridTemplateColumns: '1fr 1fr 1fr'
        },
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr 1fr'
        },
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr'
        },
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.colors.darkGreen00,
            outline: `1px solid ${theme.palette.colors.darkGreen00}`,
            borderRadius: 10
        }
    },
    imageContainer: {
        marginTop: 20,
        width: 100,
        height: 100,
        '& div': {
            width: '100% !important'
        }
    },
    noMoreContentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: 250,
        margin: '10px',
        padding: '10px auto'
    },
    text: {
        marginTop: 30,
        width: '100%'
    }
}));

const sermonActions = new Sermon().actions();

const initialState = {
    documents: [],
    collectionLength: 0,
    hasMore: false,
    lastDoc: null,
    count: 0
};

const SermonsContainer = ({ onFetch }, ref) => {
    const [sermonsInfo, setSermonsInfo] = React.useState(initialState);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isLoadingFirstTime, setIsLoadingFirstTime] = React.useState(false);
    const { i18n, lang, previousLang } = useLanguage();
    const { openAlert } = useAlert();

    const getSermonsData = React.useCallback(
        async isLoadingFirstTime => {
            try {
                if (isLoadingFirstTime) {
                    setIsLoadingFirstTime(true);
                } else {
                    setIsLoading(true);
                }
                const response = await sermonActions.queryBylang(lang);
                setSermonsInfo(response);
            } catch (error) {
                openAlert(error.name, error.message, true);
            } finally {
                if (isLoadingFirstTime) {
                    setIsLoadingFirstTime(false);
                } else {
                    setIsLoading(false);
                }
            }
        },
        [lang, openAlert]
    );

    const onSubmitFilters = React.useCallback(
        async filters => {
            try {
                setIsLoading(true);
                const response = await sermonActions.queryByFilters(
                    {
                        lang: lang,
                        ...filters
                    },
                    { count: 0 }
                );
                setSermonsInfo(response);
            } catch (error) {
                openAlert(error.name, error.message, true);
            } finally {
                setIsLoading(false);
            }
        },
        [lang, openAlert]
    );

    const onFetchMoreSermons = React.useCallback(
        async filters => {
            try {
                setIsLoading(true);
                if (sermonsInfo.count === sermonsInfo.collectionLength) {
                    setSermonsInfo(prev => ({
                        ...prev,
                        hasMore: false
                    }));
                }

                const response = await sermonActions.queryDocsOnScroll(
                    {
                        lang: lang,
                        ...filters
                    },
                    { lastDoc: sermonsInfo.lastDoc, count: sermonsInfo.count }
                );
                setSermonsInfo(prev => ({
                    documents: [...prev.documents, ...response.documents],
                    collectionLength: response.collectionLength,
                    hasMore: response.hasMore,
                    lastDoc: response.lastDoc,
                    count: response.count
                }));
            } catch (error) {
                openAlert(error.name, error.message, true);
            } finally {
                setIsLoading(false);
            }
        },
        [lang, sermonsInfo, openAlert]
    );

    const onResetFilters = React.useCallback(async () => {
        getSermonsData();
    }, [getSermonsData]);

    React.useImperativeHandle(ref, () => ({
        onSubmitFilters,
        onFetchMoreSermons,
        onResetFilters
    }));

    React.useEffect(() => {
        if (previousLang !== lang) {
            getSermonsData(true);
            return;
        }
        getSermonsData(true);
    }, [previousLang, lang, getSermonsData]);

    const classes = useStyles({ documents: sermonsInfo.documents });

    const endMessage = React.useMemo(() => {
        if (R.isEmpty(sermonsInfo.documents)) {
            return null;
        }

        return (
            <Box className={classes.noMoreContentContainer}>
                <Logo customContainerClass={classes.imageContainer} />
                <Typography
                    variant="h6"
                    align="center"
                    className={classes.text}
                >
                    {i18n.sermonsPage.noMoreContent}
                </Typography>
            </Box>
        );
    }, [classes, i18n, sermonsInfo.documents]);

    const content = React.useMemo(() => {
        if (R.isEmpty(sermonsInfo.documents)) {
            return <EmptyContent />;
        }

        return sermonsInfo.documents.map(
            ({
                id,
                title,
                speakers,
                category,
                book,
                chapterAndVerce,
                link,
                thumbnail,
                page,
                date
            }) => (
                <SermonCard
                    key={id}
                    title={title}
                    speakers={speakers}
                    category={category}
                    chapterAndVerce={chapterAndVerce}
                    book={book}
                    link={link}
                    thumbnail={thumbnail}
                    date={date}
                    page={page}
                />
            )
        );
    }, [sermonsInfo.documents]);

    if (isLoadingFirstTime) {
        return <Loader />;
    }

    return (
        <InfiniteScroll
            dataLength={sermonsInfo.documents.length}
            next={onFetch}
            className={classes.container}
            hasMore={sermonsInfo.hasMore}
            loader={isLoading ? <Loader /> : null}
            height={650}
            endMessage={endMessage}
        >
            {content}
        </InfiniteScroll>
    );
};

export default React.forwardRef(SermonsContainer);
