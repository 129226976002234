import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import {
    NotifyUserAlert,
    ProgressButton,
    TextFieldInput,
    ContactFeedbackDialog
} from '../../generic';
import { useLanguage, useValidation } from '../../hooks';
import { LanguageSwitch } from '../../navigationBar';
import { trimValues } from '../../utils';
import { ContactInfo, CONTACT_INFO_QUERY_KEY } from '../../firebase';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    container: {
        height: 800,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    switcher: {
        position: 'absolute',
        top: 20,
        left: 40
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        maxWidth: 350,
        height: 400
    },
    row: {
        marginBottom: 30
    },
    multiline: {
        marginBottom: 30,
        height: 200
    },
    button: {
        textTransform: 'capitalize'
    }
}));

export default function FallbackComponent({ error, resetErrorBoundary }) {
    const { i18n, lang } = useLanguage();

    const notifyAlertRef = React.useRef();
    const contactFeedbackDialogRef = React.useRef();

    const { validateValues, resetValidation, ifError, errorMessages } =
        useValidation(['email', 'multiline']);

    const [errorInfo, setErrorInfo] = React.useState({
        email: '',
        multiline: ''
    });

    const queryClient = useQueryClient();

    const { isLoading, mutateAsync } = useMutation(
        () => {
            const { email, multiline } = trimValues(errorInfo);
            return new ContactInfo({
                email: email,
                comments: multiline,
                lang: lang,
                status: false,
                date: new Date()
            })
                .actions()
                .addDocument();
        },
        {
            onSuccess: () => {
                contactFeedbackDialogRef.current.openDialog();
                queryClient.invalidateQueries(CONTACT_INFO_QUERY_KEY);
            }
        }
    );

    const onChange = React.useCallback(
        e => {
            resetValidation();
            const { name, value } = e.target;
            setErrorInfo(previousValues => ({
                ...previousValues,
                [name]: value
            }));
        },
        [resetValidation]
    );

    const onSubmitValues = React.useCallback(
        async e => {
            e.preventDefault();
            if (!validateValues(errorInfo)) {
                return;
            }
            try {
                await mutateAsync();
                resetErrorBoundary();
            } catch (error) {
                notifyAlertRef.current.open(error.code, error.message, true);
            }
        },
        [errorInfo, validateValues, mutateAsync, resetErrorBoundary]
    );

    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <LanguageSwitch customClass={classes.switcher} />
            <Box className={classes.container}>
                <Typography variant="h3">{i18n.errorBoundary.title}</Typography>
                <Box className={classes.row}>
                    <pre className={classes.row}>{error.message}</pre>
                </Box>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                    onSubmit={onSubmitValues}
                    className={classes.form}
                >
                    <TextFieldInput
                        className={classes.row}
                        label="Email"
                        name="email"
                        variant="outlined"
                        onChange={onChange}
                        value={errorInfo.email}
                        fullWidth
                        error={ifError('email')}
                        helperText={errorMessages('email')}
                    />
                    <TextFieldInput
                        multiline
                        rows={7}
                        maxRows={7}
                        label={i18n.errorBoundary.multiline}
                        value={errorInfo.multiline}
                        name="multiline"
                        variant="outlined"
                        onChange={onChange}
                        className={classes.multiline}
                        fullWidth
                        error={ifError('multiline')}
                        helperText={errorMessages('multiline')}
                    />
                    <ProgressButton
                        customButtonClass={classes.button}
                        isLoading={isLoading}
                        type="submit"
                    >
                        {i18n.errorBoundary.button}
                    </ProgressButton>
                </Box>
            </Box>
            <NotifyUserAlert ref={notifyAlertRef} />
            <ContactFeedbackDialog ref={contactFeedbackDialogRef} />
        </Box>
    );
}
