import { People as PeopleIcon, Title as TitleIcon } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { Sermon, SERMONS_QUERY_KEY } from '../../firebase';
import DataGridDropdownMenu from '../DataGridDropdownMenu';

const columns = [
    {
        field: 'title',
        headerName: 'Title',
        width: 80,
        sortable: false,
        renderCell: params => {
            return (
                <Tooltip title={params.row.title}>
                    <TitleIcon />
                </Tooltip>
            );
        }
    },
    {
        field: 'speakers',
        headerName: 'Speakers',
        width: 140,
        renderCell: params => {
            return (
                <Tooltip title={params.row.speakers.join(', ')}>
                    <PeopleIcon />
                </Tooltip>
            );
        }
    },
    { field: 'category', headerName: 'Category', flex: 1, sortable: true },
    {
        field: 'book',
        headerName: 'Book',
        flex: 1,
        sortable: true
    },
    {
        field: 'page',
        headerName: 'Page',
        width: 90,
        sortable: false
    },
    {
        field: 'chapterAndVerce',
        headerName: 'Chapter and verce',
        flex: 1,
        sortable: false
    },
    {
        field: 'link',
        headerName: 'Link',
        flex: 1,
        sortable: false
    },
    {
        field: 'lang',
        headerName: 'Lang',
        width: 90,
        sortable: false
    },
    {
        field: 'date',
        headerName: 'Date',
        flex: 1
    },
    {
        field: '',
        headerName: 'Actions',
        sortable: false,
        width: 100,
        disableClickEventBubbling: true,
        renderCell: params => {
            const onDelete = () => {
                return new Sermon().actions().deleteDocument(params.row.id);
            };

            return (
                <DataGridDropdownMenu
                    id={params.row.id}
                    rowData={params.row}
                    onDelete={onDelete}
                    mutationQueryKey={SERMONS_QUERY_KEY}
                    isEditingFor="sermon"
                />
            );
        }
    }
];

const useStyles = makeStyles(theme => ({
    dataGridRoot: {
        marginTop: 20
    }
}));

export default function SermonsDataGrid({ loading, data }) {
    const [pageSize, setPageSize] = React.useState(5);
    const classes = useStyles();

    const rows = React.useMemo(() => {
        if (!data) {
            return [];
        }
        return data;
    }, [data]);

    return (
        <DataGrid
            classes={{ root: classes.dataGridRoot }}
            columns={columns}
            rows={rows}
            loading={loading}
            autoHeight
            autoPageSize
            pageSize={pageSize}
            rowsPerPageOptions={[5, 10, 25]}
            onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        />
    );
}
