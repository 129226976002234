import React from 'react';
import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    labelFocused: {
        color: `${theme.palette.colors.darkGreen00} !important`
    }
}));

export default function TextFieldInput({ ...props }) {
    const classes = useStyles();

    return (
        <TextField
            {...props}
            InputLabelProps={{
                classes: { focused: classes.labelFocused }
            }}
        />
    );
}
