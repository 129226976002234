import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useLanguage } from '../hooks';
import { ContactInfo, CONTACT_INFO_QUERY_KEY } from '../firebase';
import { parseISO } from 'date-fns';

export default function AdminCheckbox({ data }) {
    const [checked, setChecked] = React.useState(data.status);

    const queryClient = useQueryClient();

    const { mutateAsync: onToggleAction } = useMutation(
        value =>
            new ContactInfo({
                ...data,
                date: parseISO(data.date.split('/').reverse().join('-')),
                status: value
            })
                .actions()
                .editDocument(data.id),
        {
            onSuccess: () =>
                queryClient.invalidateQueries(CONTACT_INFO_QUERY_KEY)
        }
    );

    const onChange = React.useCallback(
        event => {
            setChecked(event.target.checked);
            onToggleAction(event.target.checked);
        },
        [onToggleAction]
    );

    const { i18n } = useLanguage();

    const labeValue = React.useMemo(() => {
        return checked
            ? i18n.datagrids.statusTextRead
            : i18n.datagrids.statusTextUnread;
    }, [checked, i18n]);

    return (
        <FormGroup>
            <FormControlLabel
                control={<Checkbox checked={checked} />}
                label={labeValue}
                onChange={onChange}
                sx={{ fontSize: '0.85rem !important' }}
            />
        </FormGroup>
    );
}
