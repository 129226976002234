import { Box, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Sermon, SERMONS_QUERY_KEY } from '../../firebase';
import {
    Datepicker,
    GenericDialog,
    OptionSelector,
    ProgressButton,
    SpeakersSelector,
    TextFieldInput
} from '../../generic';
import { useAlert, useLanguage, useValidation } from '../../hooks';

const defaultValues = {
    id: '',
    title: '',
    speakers: [],
    book: '',
    link: '',
    page: '',
    category: '',
    chapterAndVerce: '',
    lang: '',
    date: new Date()
};

const useStyles = makeStyles(theme => ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: 800,
        justifyContent: 'space-evenly'
    },
    textField: {
        width: '100%'
    }
}));

const SermonDialog = ({ _ }, ref) => {
    const [sermonValues, setSermonValues] = React.useState(defaultValues);
    const genericDialogRef = React.useRef();
    const { i18n } = useLanguage();
    const { openAlert } = useAlert();

    const { validateValues, ifError, errorMessages, resetValidation } =
        useValidation([
            'title',
            'speakers',
            'link',
            'date',
            'category',
            'lang'
        ]);

    const queryClient = useQueryClient();

    const { isLoading, mutateAsync } = useMutation(
        () => {
            const {
                title,
                speakers,
                book,
                link,
                page,
                category,
                chapterAndVerce,
                lang,
                date
            } = sermonValues;
            if (sermonValues.id) {
                return new Sermon({
                    title,
                    speakers,
                    book,
                    link,
                    page,
                    category,
                    chapterAndVerce,
                    lang,
                    date
                })
                    .actions()
                    .editDocument(sermonValues.id);
            }

            return new Sermon({
                title,
                speakers,
                book,
                link,
                page,
                category,
                chapterAndVerce,
                lang,
                date
            })
                .actions()
                .addDocument();
        },
        { onSuccess: () => queryClient.invalidateQueries(SERMONS_QUERY_KEY) }
    );

    const onChange = React.useCallback(
        (event, selectedValue, optionName) => {
            resetValidation();
            const { name, value } = event.target;

            if (optionName) {
                setSermonValues(prevValues => ({
                    ...prevValues,
                    [optionName]: selectedValue
                }));
                return;
            }

            setSermonValues(prevValues => ({
                ...prevValues,
                [name]: value
            }));
        },
        [resetValidation]
    );

    const openDialog = React.useCallback(() => {
        genericDialogRef.current.open();
    }, []);

    const closeDialog = React.useCallback(() => {
        resetValidation();
        setSermonValues(defaultValues);
        genericDialogRef.current.close();
    }, [resetValidation]);

    React.useImperativeHandle(ref, () => ({
        openDialog: values => {
            if (values) {
                setSermonValues({ ...values, date: new Date(values.date) });
            }
            openDialog();
        }
    }));

    const onSubmitValues = React.useCallback(async () => {
        const {
            title,
            speakers,
            book,
            link,
            page,
            category,
            chapterAndVerce,
            lang,
            date
        } = sermonValues;
        if (
            !validateValues({
                title,
                speakers,
                book,
                link,
                page,
                category,
                chapterAndVerce,
                lang,
                date
            })
        ) {
            return;
        }
        try {
            await mutateAsync();
            closeDialog();
        } catch (error) {
            openAlert(error.name, error.message, true);
        }
    }, [sermonValues, validateValues, mutateAsync, openAlert, closeDialog]);

    const classes = useStyles();

    return (
        <GenericDialog
            onClose={closeDialog}
            ref={genericDialogRef}
            submitButton={
                <ProgressButton isLoading={isLoading} onClick={onSubmitValues}>
                    {i18n.genericDialog.adminDialogs.submit}
                </ProgressButton>
            }
            title={
                sermonValues.id
                    ? i18n.genericDialog.adminDialogs.editTitle
                    : i18n.genericDialog.adminDialogs.addTitle
            }
        >
            <Box className={classes.formContainer}>
                <TextFieldInput
                    name="title"
                    label={i18n.genericDialog.adminDialogs.title}
                    value={sermonValues.title}
                    onChange={onChange}
                    className={classes.textField}
                    error={ifError('title')}
                    helperText={errorMessages('title')}
                />
                <OptionSelector
                    TextFieldComponent={TextField}
                    name="category"
                    label={i18n.genericDialog.adminDialogs.category}
                    value={sermonValues.category}
                    onChange={onChange}
                    className={classes.textField}
                    error={ifError('category')}
                    helperText={errorMessages('category')}
                    langDataOptions={i18n.options.categories}
                />
                <SpeakersSelector
                    id="dialogSpeakers"
                    name="speakers"
                    value={sermonValues.speakers}
                    onChange={onChange}
                    label={i18n.genericDialog.adminDialogs.speaker}
                    error={ifError('speakers')}
                    helperText={errorMessages('speakers')}
                    containerClass={classes.filters}
                />
                <OptionSelector
                    TextFieldComponent={TextField}
                    name="book"
                    label={i18n.genericDialog.adminDialogs.book}
                    value={sermonValues.book}
                    onChange={onChange}
                    className={classes.textField}
                    error={ifError('book')}
                    helperText={errorMessages('book')}
                    langDataOptions={i18n.options.books}
                />
                <TextFieldInput
                    name="page"
                    label={i18n.genericDialog.adminDialogs.page}
                    value={sermonValues.page}
                    onChange={onChange}
                    className={classes.textField}
                    error={ifError('page')}
                    helperText={errorMessages('page')}
                />
                <TextFieldInput
                    name="chapterAndVerce"
                    label={i18n.genericDialog.adminDialogs.chapterAndVerce}
                    value={sermonValues.chapterAndVerce}
                    onChange={onChange}
                    className={classes.textField}
                    error={ifError('chapterAndVerce')}
                    helperText={errorMessages('chapterAndVerce')}
                />
                <TextFieldInput
                    name="link"
                    label={i18n.genericDialog.adminDialogs.link}
                    value={sermonValues.link}
                    onChange={onChange}
                    className={classes.textField}
                    error={ifError('link')}
                    helperText={errorMessages('link')}
                />
                <OptionSelector
                    TextFieldComponent={TextField}
                    name="lang"
                    label={i18n.genericDialog.adminDialogs.lang}
                    value={sermonValues.lang}
                    onChange={onChange}
                    className={classes.textField}
                    error={ifError('lang')}
                    helperText={errorMessages('lang')}
                    langDataOptions={['en', 'el']}
                />
                <Datepicker
                    name="date"
                    value={sermonValues.date}
                    label={i18n.genericDialog.adminDialogs.date}
                    setValues={setSermonValues}
                    className={classes.textField}
                    fullWidth
                    error={ifError('date')}
                    helperText={errorMessages('date')}
                />
            </Box>
        </GenericDialog>
    );
};

export default React.forwardRef(SermonDialog);
