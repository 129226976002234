import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from '../hooks';
import links from '../../links';

export default function ProtectedRoute({ Component, ...props }) {
    const { isAuthenticated } = useAuth();

    if (!isAuthenticated) {
        return <Route {...props} render={() =>
            !isAuthenticated ? <Redirect to={links.login} /> : <Component />
          } />;
    }

    return <Route {...props} render={props => <Component {...props} />} />;
}
