import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';
import LogoImage from './logo.png';

const useStyles = makeStyles(theme => ({
    imageContainer: {
        width: 60,
        height: 60,
        '& div': {
            width: '100% !important'
        }
    },
    logoImg: {
        width: '100% !important',
        height: '100% !important',
        borderRadius: '100%'
    }
}));

export default function Logo({ customContainerClass, customLogoClass }) {
    const classes = useStyles();
    return (
        <Box
            className={clsx({
                [classes.imageContainer]: !customContainerClass,
                [customContainerClass]: customContainerClass
            })}
        >
            <img
                src={LogoImage}
                className={clsx({
                    [classes.logoImg]: !customLogoClass,
                    [customLogoClass]: customLogoClass
                })}
                alt="logo"
            />
        </Box>
    );
}
