import {
    Facebook as FbIcon,
    Instagram as InstaIcon,
    YouTube as YouTubeIcon
} from '@mui/icons-material';
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useLanguage } from '../hooks';

const useStyles = makeStyles(theme => ({
    links: {
        textDecoration: 'none',
        color: ({ isContactPage }) =>
            isContactPage ? 'rgba(0, 0, 0, 0.87)' : theme.palette.colors.white
    },
    titleList: {
        textDecoration: 'underline',
        color: `${theme.palette.colors.white} !important`
    },
    whiteText: {
        color: theme.palette.colors.white
    },
    instagram: {
        color: theme.palette.colors.instagram
    },
    fb: {
        color: theme.palette.colors.facebook
    },
    youtube: {
        color: theme.palette.colors.youTube
    }
}));

export default function SocialMedia({ isContactPage }) {
    const { i18n } = useLanguage();
    const classes = useStyles({ isContactPage });
    return (
        <List>
            <ListItem>
                <ListItemText
                    primary={
                        <Typography
                            variant="h5"
                            className={!isContactPage && classes.titleList}
                        >
                            {i18n.footer.socialMedia}
                        </Typography>
                    }
                />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <InstaIcon className={classes.instagram} />
                </ListItemIcon>
                <ListItemText
                    primary={
                        <a
                            href="https://www.instagram.com/powerofgodgr/?hl=el"
                            target="_blank"
                            rel="noreferrer"
                            className={classes.links}
                        >
                            Instagram
                        </a>
                    }
                />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <FbIcon className={classes.fb} />
                </ListItemIcon>
                <ListItemText
                    primary={
                        <a
                            href="https://m.facebook.com/powerofgodgr-106410071026992/"
                            target="_blank"
                            rel="noreferrer"
                            className={classes.links}
                        >
                            Facebook
                        </a>
                    }
                />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <YouTubeIcon className={classes.youtube} />
                </ListItemIcon>
                <ListItemText
                    primary={
                        <a
                            href="https://m.youtube.com/c/PowerofGodGR/videos"
                            target="_blank"
                            rel="noreferrer"
                            className={classes.links}
                        >
                            YouTube
                        </a>
                    }
                />
            </ListItem>
        </List>
    );
}
