import React from 'react';
import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowUpward as ArrowUp } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
    button: {
        position: 'fixed !important',
        zIndex: '1350 !important',
        bottom: 10,
        right: 10,
        background: `${theme.palette.colors.darkGreen00} !important`
    },
    icon: {
        color: theme.palette.colors.lightGrey
    }
}));

export default function ScrollToTopButton() {
    const [isVisible, setIsVisible] = React.useState(false);

    const toggleVisible = React.useCallback(() => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setIsVisible(true);
        } else if (scrolled <= 300) {
            setIsVisible(false);
        }
    }, []);

    const scrollToTop = React.useCallback(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    React.useEffect(() => {
        window.addEventListener('scroll', toggleVisible);
        return () => window.removeEventListener('scroll', toggleVisible);
    }, [toggleVisible]);

    const classes = useStyles();

    return (
        <IconButton
            onClick={scrollToTop}
            className={classes.button}
            sx={{ display: isVisible ? 'inline-flex' : 'none' }}
        >
            <ArrowUp className={classes.icon} />
        </IconButton>
    );
}
