import { Delete as DeleteIcon } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
    button: {
        display: 'flex',
        color: theme.palette.primary.main
    },
    icon: {
        color: theme.palette.primary.main
    },
    text: {
        paddingRight: 10
    }
}));

export default function DeleteAction({ onClick, children }) {
    const classes = useStyles();
    return (
        <IconButton className={classes.button} onClick={onClick}>
            <DeleteIcon className={classes.icon} />
            {children && (
                <Typography variant="body1" className={classes.text}>
                    {children}
                </Typography>
            )}
        </IconButton>
    );
}
