import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { DeleteAction, EditAction } from '../admin';
import { useAdminDialogs, useLanguage } from '../hooks';
import { DropDownMenu } from '../navigationBar';

const useStyles = makeStyles(theme => ({
    customColor: {
        color: `${theme.palette.colors.darkGreen00} !important`
    },
    menu: {
        '& ul': {
            padding: 5,
            background: theme.palette.colors.white,
            display: 'flex',
            flexDirection: 'column'
        }
    }
}));

export default function DataGridDropdownMenu({
    id,
    rowData,
    onDelete,
    mutationQueryKey,
    isEditingFor
}) {
    const { i18n } = useLanguage();

    const {
        openSermonDialog,
        openSpeakerDialog,
        openConfirmDialog,
        openBroadcastDialog,
        openPlaylistDialog
    } = useAdminDialogs();

    const editAction = React.useCallback(
        rowData => {
            if (isEditingFor === 'sermon') {
                return () => openSermonDialog(rowData);
            }
            if (isEditingFor === 'speaker') {
                return () => openSpeakerDialog(rowData);
            }
            if (isEditingFor === 'broadcast') {
                return () => openBroadcastDialog(rowData);
            }
            if (isEditingFor === 'playlist') {
                return () => openPlaylistDialog(rowData);
            }
        },
        [
            isEditingFor,
            openSermonDialog,
            openSpeakerDialog,
            openBroadcastDialog,
            openPlaylistDialog
        ]
    );

    const onDeleteAction = React.useCallback(() => {
        openConfirmDialog(onDelete, mutationQueryKey, rowData.id);
    }, [onDelete, mutationQueryKey, openConfirmDialog, rowData]);

    const classes = useStyles();

    return (
        <DropDownMenu
            id={id}
            title={i18n.adminPage.itemActions.options}
            customIconClass={classes.customColor}
            customMenuClass={classes.menu}
            customDropdownButtonClass={classes.customColor}
        >
            <EditAction onClick={editAction(rowData)}>
                {i18n.adminPage.itemActions.edit}
            </EditAction>
            <DeleteAction onClick={onDeleteAction}>
                {i18n.adminPage.itemActions.delete}
            </DeleteAction>
        </DropDownMenu>
    );
}
