import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDocs,
    limit,
    orderBy,
    query,
    setDoc,
    Timestamp,
    startAfter,
    where
} from 'firebase/firestore';
import { firestore } from '../index';
import { formatDate } from '../../utils';
import { DESC } from './constants';

export default class DatabaseActions {
    constructor(props) {
        this.db = firestore;
        this.data = props.data ? props.data : null;
        this.collection = props.collection;
        this.order = props.order;
        this.collectionLength = null;
        this.documentsRef = collection(this.db, this.collection);
    }

    convertDateStringToTimeStamp = () => {
        const dateObj = new Date(this.data.date);
        this.data.date = Timestamp.fromDate(dateObj);
    };

    createAnObjectBasedOnValues = obj => {
        const filteredObject = {};
        const entries = Object.entries(obj);
        for (const [key, value] of entries) {
            if (key === 'speakers' && value.length > 0) {
                filteredObject[key] = value;
            } else if (key !== 'speakers' && value) {
                filteredObject[key] = value;
            }
        }
        return filteredObject;
    };

    firebaseStructuredObject = (id, object) => {
        const structuredObject = { id };
        const entries = Object.entries(object);
        for (const [key, value] of entries) {
            if (key === 'date') {
                if (value instanceof Timestamp) {
                    structuredObject[key] = formatDate(value.toDate(), true);
                } else {
                    structuredObject[key] = value;
                }
            } else {
                structuredObject[key] = value;
            }
        }

        return structuredObject;
    };

    constructConstraints = values => {
        const objectWithValues = this.createAnObjectBasedOnValues(values);
        const entries = Object.entries(objectWithValues);
        const constraints = [];

        for (const [key, value] of entries) {
            if (key === 'dateFrom' && value instanceof Date) {
                const date = Timestamp.fromDate(value);
                constraints.push(where('date', '>=', date));
            } else if (key === 'dateTo' && value instanceof Date) {
                const date = Timestamp.fromDate(value);
                constraints.push(where('date', '<=', date));
            } else if (value instanceof Date) {
                const date = Timestamp.fromDate(value);
                constraints.push(where(key, '>=', date));
            } else if (key === 'speakers') {
                constraints.push(where(key, 'array-contains-any', value));
            } else if (value) {
                constraints.push(where(key, '==', value));
            }
        }

        return constraints;
    };

    hasMore = (count, collectionLength) => {
        let hasMore = true;
        if (count >= collectionLength) {
            hasMore = false;
        }

        return hasMore;
    };

    addDocument = async () => {
        try {
            await addDoc(this.documentsRef, this.data);
        } catch (error) {
            console.log(error);
        }
    };

    deleteDocument = async docId => {
        try {
            await deleteDoc(doc(this.db, this.collection, docId));
        } catch (error) {
            console.log(error);
        }
    };

    editDocument = async docId => {
        try {
            await setDoc(doc(this.db, this.collection, docId), this.data);
        } catch (error) {
            console.log(error);
        }
    };

    getDocumentsForAdmin = async limitValue => {
        try {
            const documents = [];

            if (!limitValue) {
                return documents;
            }

            const documentsQuery = query(
                this.documentsRef,
                orderBy(this.order, DESC),
                limit(limitValue)
            );

            const documentsSnapshot = await getDocs(documentsQuery);

            documentsSnapshot.forEach(doc => {
                const docData = doc.data();
                documents.push(this.firebaseStructuredObject(doc.id, docData));
            });

            return documents;
        } catch (error) {
            console.log(error);
        }
    };

    getDocumentsForClient = async lang => {
        try {
            const documents = [];

            const documentsQuery = query(
                this.documentsRef,
                where('lang', '==', `${lang}`),
                orderBy(this.order, DESC)
            );

            const documentsSnapshot = await getDocs(documentsQuery);

            documentsSnapshot.forEach(doc => {
                const docData = doc.data();
                documents.push(this.firebaseStructuredObject(doc.id, docData));
            });

            return documents;
        } catch (error) {
            console.log(error);
        }
    };

    getDocumentsForClientWithoutLang = async () => {
        try {
            const documents = [];

            const documentsQuery = query(
                this.documentsRef,
                orderBy(this.order, DESC)
            );

            const documentsSnapshot = await getDocs(documentsQuery);

            documentsSnapshot.forEach(doc => {
                const docData = doc.data();
                documents.push(this.firebaseStructuredObject(doc.id, docData));
            });

            return documents;
        } catch (error) {
            console.log(error);
        }
    };

    getDocumentsForClientWithLimit = async (lang, limitValue) => {
        try {
            const documents = [];

            const documentsQuery = query(
                this.documentsRef,
                where('lang', '==', `${lang}`),
                orderBy(this.order, DESC),
                limit(limitValue || 6)
            );

            const documentsSnapshot = await getDocs(documentsQuery);

            documentsSnapshot.forEach(doc => {
                const docData = doc.data();
                documents.push(this.firebaseStructuredObject(doc.id, docData));
            });

            return documents;
        } catch (error) {
            console.log(error);
        }
    };

    queryBylang = async lang => {
        try {
            const documents = [];

            this.collectionLength = await this.getColletionLength({
                lang
            });

            const documentsQuery = query(
                this.documentsRef,
                where('lang', '==', `${lang}`),
                orderBy(this.order, DESC),
                limit(25)
            );

            const documentsSnapshot = await getDocs(documentsQuery);

            documentsSnapshot.forEach(doc => {
                const docData = doc.data();
                documents.push(this.firebaseStructuredObject(doc.id, docData));
            });

            return {
                documents,
                collectionLength: this.collectionLength,
                hasMore: true,
                lastDoc:
                    documentsSnapshot.docs[documentsSnapshot.docs.length - 1],
                count: documentsSnapshot.size
            };
        } catch (error) {
            console.log(error);
        }
    };

    queryByFilters = async (values, settings) => {
        try {
            const documents = [];

            this.collectionLength = await this.getColletionLength(values);

            const documentsRef = collection(this.db, this.collection);

            const constraints = this.constructConstraints(values);

            const documentsQuery = query(
                documentsRef,
                ...constraints,
                orderBy(this.order, DESC),
                limit(25)
            );

            const documentsSnapshot = await getDocs(documentsQuery);

            documentsSnapshot.forEach(doc => {
                const docData = doc.data();
                documents.push(this.firebaseStructuredObject(doc.id, docData));
            });

            if (!settings) {
                return documents;
            }

            const count = settings.count + documentsSnapshot.size;

            return {
                documents,
                collectionLength: this.collectionLength,
                hasMore: this.hasMore(count, this.collectionLength),
                lastDoc:
                    documentsSnapshot.docs[documentsSnapshot.docs.length - 1],
                count
            };
        } catch (error) {
            console.log(error);
        }
    };

    queryDocsOnScroll = async (values, settings) => {
        try {
            const documents = [];

            let lastDocFound;

            if (!settings.lastDoc) {
                lastDocFound = await this.getLastDoc(values);
            }

            if (!this.collectionLength) {
                this.collectionLength = await this.getColletionLength(values);
            }

            const documentsRef = collection(this.db, this.collection);

            const constraints = this.constructConstraints(values);

            const documentsQuery = query(
                documentsRef,
                ...constraints,
                orderBy(this.order, DESC),
                startAfter(settings.lastDoc || lastDocFound),
                limit(25)
            );

            const documentsSnapshot = await getDocs(documentsQuery);

            documentsSnapshot.forEach(doc => {
                const docData = doc.data();
                documents.push(
                    this.firebaseStructuredObject(doc.id, {
                        date: docData.date.toDate(),
                        ...docData
                    })
                );
            });

            const count = settings.count + documentsSnapshot.size;

            return {
                documents,
                collectionLength: this.collectionLength,
                hasMore: this.hasMore(count, this.collectionLength),
                lastDoc:
                    documentsSnapshot.docs[documentsSnapshot.docs.length - 1],
                count: count
            };
        } catch (error) {
            console.log(error);
        }
    };

    getLastDoc = async values => {
        const documentsRef = collection(this.db, this.collection);
        const constraints = this.constructConstraints(values);
        const documentsQuery = query(
            documentsRef,
            ...constraints,
            orderBy(this.order, DESC),
            limit(25)
        );

        const documentsSnapshot = await getDocs(documentsQuery);

        return documentsSnapshot.docs[documentsSnapshot.docs.length - 1];
    };

    getColletionLength = async values => {
        const documentsRef = collection(this.db, this.collection);
        const constraints = this.constructConstraints(values);
        const documentsQuery = query(
            documentsRef,
            ...constraints,
            orderBy(this.order, DESC)
        );

        const documentsSnapshot = await getDocs(documentsQuery);

        return documentsSnapshot.size;
    };
}
