import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import FallbackComponent from './FallbackComponent';

export default function ErrorBoundaryComponent({ children }) {
    const onReset = React.useCallback(() => {}, []);

    return (
        <ErrorBoundary FallbackComponent={FallbackComponent} onReset={onReset}>
            {children}
        </ErrorBoundary>
    );
}
