import { Autocomplete, Box, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';
import { useQuery } from 'react-query';
import { Speaker, SPEAKERS_QUERY_KEY } from '../firebase';
import { useAlert, useLanguage } from '../hooks';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        width: '100%'
    },
    autocomplete: {
        width: '100%'
    },
    labelFocused: {
        color: `${theme.palette.colors.darkGreen00} !important`
    }
}));

export default function SpeakersSelector({
    id,
    onChange,
    name,
    value,
    label,
    error,
    helperText,
    className,
    containerClass
}) {
    const { lang } = useLanguage();
    const { openAlert } = useAlert();

    const { data, isLoading } = useQuery(
        [SPEAKERS_QUERY_KEY, lang],
        () => {
            return new Speaker().actions().getDocumentsForClient(lang);
        },
        {
            onError: error => openAlert(error.name, error.message, true)
        }
    );

    const speakers = React.useMemo(() => {
        if (!data) {
            return [];
        }
        return data.map(speaker => speaker.fullname);
    }, [data]);

    const classes = useStyles();

    return (
        <Box className={clsx(classes.container, containerClass)}>
            <Autocomplete
                id={id}
                disablePortal
                options={speakers}
                classes={{ root: clsx(classes.autocomplete, className) }}
                getOptionLabel={option => option}
                onChange={(e, v) => onChange(e, v, name)}
                value={value}
                loading={isLoading}
                multiple
                renderInput={params => (
                    <TextField
                        color="primary"
                        label={label}
                        error={error}
                        helperText={helperText}
                        {...params}
                        InputLabelProps={{
                            classes: { focused: classes.labelFocused }
                        }}
                    />
                )}
            />
        </Box>
    );
}
