import { DatabaseActions } from '../index';
import { BROADCAST_COLLECTION, ORDER_BY_DATE } from './constants';
import { Timestamp } from '@firebase/firestore';

export default class Broadcast {
    constructor(props) {
        this.data = props && {
            ...props,
            videoUrl: null
        };
    }

    formatVideoUrl() {
        return (this.videoUrl = `https://youtu.be/${this.data.videoId}`);
    }

    timestampFormatter = () => {
        const date = this.data.date;
        return (this.data.date = Timestamp.fromDate(date));
    };

    getState = () => {
        if (!this.data) {
            return null;
        }
        return {
            ...this.data,
            date: this.timestampFormatter(),
            videoUrl: this.formatVideoUrl()
        };
    };

    actions = () =>
        new DatabaseActions({
            data: this.getState(),
            collection: BROADCAST_COLLECTION,
            order: ORDER_BY_DATE
        });
}
