import { Box, Button, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(theme => ({
    container: {
        marginLeft: 10,
        fontSize: 20
    },
    loader: { marginLeft: 5 },
    button: {
        textTransform: 'capitalize !important',
        background: `${theme.palette.colors.darkGreen00} !important`
    },
    btnDisabled: {
        backgroundColor: 'rgba(0, 0, 0, 0.12) !important'
    }
}));

export default function ProgressButton({
    customContainerClass,
    onClick,
    isLoading,
    children,
    customButtonClass,
    variant = 'contained',
    color,
    progressColor = 'error',
    disabled,
    iconSize = 20,
    ...props
}) {
    const classes = useStyles();

    return (
        <Box
            className={clsx({
                [classes.container]: !customContainerClass,
                [customContainerClass]: customContainerClass
            })}
        >
            <Button
                onClick={onClick}
                variant={variant}
                color={color}
                disabled={disabled}
                className={clsx({
                    [classes.button]: !customButtonClass,
                    [customButtonClass]: customButtonClass
                })}
                classes={{ disabled: classes.btnDisabled }}
                {...props}
            >
                {children}
                {isLoading && (
                    <CircularProgress
                        className={classes.loader}
                        size={iconSize}
                        color={progressColor}
                    />
                )}
            </Button>
        </Box>
    );
}
