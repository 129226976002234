import { getAuth } from '@firebase/auth';
import { initializeApp } from 'firebase/app';
import DatabaseActions from './models/DatabaseActions';
import Sermon from './models/Sermon';
import Speaker from './models/Speaker';
import ContactInfo from './models/ContactInfo';
import Broadcast from './models/Broadcast';
import Playlist from './models/Playlist';
import { getFirestore } from 'firebase/firestore';

import {
    PLAYLIST_QUERY_KEY,
    BROADCAST_QUERY_KEY,
    SERMONS_QUERY_KEY,
    SPEAKERS_QUERY_KEY,
    CONTACT_INFO_QUERY_KEY
} from './models/constants';

const app = initializeApp(require('../../env.json').firebase);

const auth = getAuth();
const firestore = getFirestore(app);

export {
    firestore,
    auth,
    Broadcast,
    Playlist,
    DatabaseActions,
    ContactInfo,
    Sermon,
    Speaker,
    SERMONS_QUERY_KEY,
    BROADCAST_QUERY_KEY,
    PLAYLIST_QUERY_KEY,
    CONTACT_INFO_QUERY_KEY,
    SPEAKERS_QUERY_KEY
};
