import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
    ProtectedRoute,
    Loader,
    ScrollToTopButton
} from './components/generic';
import { useAuth } from './components/hooks';
import HomePage from './pages/userPages/HomePage';
import NotFoundPage from './pages/userPages/NotFoundPage';
import links from './links';

const Login = React.lazy(() => import('./pages/adminPages/LoginPage'));
const OurHistory = React.lazy(() => import('./pages/userPages/OurHistoryPage'));
const WhatWeBelieve = React.lazy(() =>
    import('./pages/userPages/WhatWeBelievePage')
);
const Services = React.lazy(() => import('./pages/userPages/ServicesPage'));
const Youth = React.lazy(() => import('./pages/userPages/YouthPage'));
const Sermons = React.lazy(() => import('./pages/userPages/SermonsPage'));
const Contact = React.lazy(() => import('./pages/userPages/ContactPage'));

const AdminSermons = React.lazy(() =>
    import('./pages/adminPages/AdminSermons')
);
const AdminBroadcast = React.lazy(() =>
    import('./pages/adminPages/AdminBroadcast')
);
const AdminPlaylist = React.lazy(() =>
    import('./pages/adminPages/AdminPlaylist')
);
const AdminSpeakers = React.lazy(() =>
    import('./pages/adminPages/AdminSpeakers')
);
const AdminContactInfo = React.lazy(() =>
    import('./pages/adminPages/AdminContactInfo')
);

export default function App() {
    const { isAuthenticated } = useAuth();

    return (
        <>
            <React.Suspense fallback={<Loader customHeight />}>
                <Switch>
                    <Route exact path={links.home} component={HomePage} />
                    <Route
                        exact
                        path={links.login}
                        render={() =>
                            isAuthenticated ? (
                                <Redirect to={links.admin} />
                            ) : (
                                <Login />
                            )
                        }
                    />
                    <Route
                        exact
                        path={links.ourHistory}
                        component={OurHistory}
                    />
                    <Route
                        exact
                        path={links.whatWeBelieve}
                        component={WhatWeBelieve}
                    />
                    <Route
                        exact
                        path={links.churchService}
                        component={Services}
                    />
                    <Route exact path={links.youth} component={Youth} />
                    <Route exact path={links.sermons} component={Sermons} />
                    <Route exact path={links.contact} component={Contact} />
                    <ProtectedRoute
                        exact
                        path={links.admin}
                        Component={AdminSermons}
                    />
                    <ProtectedRoute
                        exact
                        path={links.adminBroadcast}
                        Component={AdminBroadcast}
                    />
                    <ProtectedRoute
                        exact
                        path={links.adminPlaylist}
                        Component={AdminPlaylist}
                    />
                    <ProtectedRoute
                        exact
                        path={links.adminSpeakers}
                        Component={AdminSpeakers}
                    />
                    <ProtectedRoute
                        exact
                        path={links.adminContactInfo}
                        Component={AdminContactInfo}
                    />
                    <Route path="*">
                        <NotFoundPage />
                    </Route>
                </Switch>
            </React.Suspense>
            <ScrollToTopButton />
        </>
    );
}
