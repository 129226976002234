import { capitalizeString } from '../../utils';
import { ORDER_BY_LASTNAME, SPEAKERS_COLLECTIONS } from './constants';
import { DatabaseActions } from '../index';

export default class Speaker {
    constructor(props) {
        this.data = props && {
            firstname: capitalizeString(props.firstname),
            lastname: capitalizeString(props.lastname),
            lang: props.lang,
        };
    }

    getState = () => {
        if (!this.data) {
            return null;
        }
        return {
            ...this.data,
            fullname: `${this.data.firstname} ${this.data.lastname}`
        };
    };

    actions = () =>
        new DatabaseActions({
            data: this.getState(),
            collection: SPEAKERS_COLLECTIONS,
            order: ORDER_BY_LASTNAME
        });
}
