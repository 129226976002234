import React from "react";
import { Tooltip, IconButton, Box, useMediaQuery } from "@mui/material";
import { Visibility as VisibilityIcon } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 280,
    padding: 5,
  },
  tooltip: {
    position: "absolute",
    bottom: 50,
    right: 0,
    left: 15,
  },
  iconButton: {
    backgroundColor: `${theme.palette.colors.darkGreen00} !important`,
    width: 30,
    height: 30,
  },
  icon: {
    color: "#fff",
  },
}));

export default function InstragramItem({ caption, link, src, mediaType }) {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const classes = useStyles();


  const renderCaption = React.useCallback(() => {
    if (!caption || isSmallScreen) {
      return null;
    }

    return (
      <Tooltip title={caption} className={classes.tooltip}>
        <IconButton className={classes.iconButton}>
          <VisibilityIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
    );
  }, [caption, classes, isSmallScreen]);

  if (mediaType === "VIDEO") {
    return (
      <Box position="relative" className={classes.container}>
        <a href={link} target="_blank" rel="noreferrer">
          <video src={src} alt={caption} width="100%" />
        </a>
        {renderCaption()}
      </Box>
    );
  }
  return (
    <Box position="relative" className={classes.container}>
      <a href={link} target="_blank" rel="noreferrer">
        <img src={src} alt={caption} width="100%" />
      </a>
      {renderCaption()}
    </Box>
  );
}
