import { AppBar, Box, Toolbar, Container } from '@mui/material';
import { makeStyles } from '@mui/styles/';
import React from 'react';
import { DesktopLinks, MobileLinks } from '../navigationBar';

const useStyles = makeStyles(theme => ({
    appBar: {
        background: `${theme.palette.colors.darkGreen00} !important`
    },
    box: {
        width: '100%'
    }
}));

export default function NavigationBar() {
    const classes = useStyles();
    return (
        <AppBar className={classes.appBar} position="sticky">
            <Container maxWidth="xl">
                <Toolbar>
                    <Box
                        className={classes.box}
                        sx={{ display: { xs: 'none', lg: 'block' } }}
                    >
                        <DesktopLinks />
                    </Box>
                    <Box
                        className={classes.box}
                        sx={{ display: { xs: 'block', lg: 'none' } }}
                    >
                        <MobileLinks />
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
