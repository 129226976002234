import React from 'react';
import { Helmet } from 'react-helmet';
import { useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useLanguage } from '../hooks';
import links from '../../links';

const HelmetContext = React.createContext();

const isPageExisting = (links, pathName) => {
    let doesExists = false;
    links.forEach(link => {
        if (link === pathName) {
            doesExists = true;
        }
    });
    return doesExists;
};

const getPageInformation = (i18n, pathname) => {
    const linkValues = Object.values(links);
    if (!isPageExisting(linkValues, pathname)) {
        return {
            title: i18n.notFoundPage.title,
            canonical: pathname,
            description: '',
            keywords: ''
        };
    }
    return {
        title: i18n.pages[pathname].title,
        canonical: pathname,
        description: i18n.pages[pathname].description,
        keywords: i18n.pages[pathname].keywords
    };
};

const defaultState = {
    title: '',
    canonical: '',
    description: '',
    keywords: ''
};

export default function HelmetProvider({ children }) {
    const location = useLocation();
    const { i18n } = useLanguage();

    const [pageInfo, setPageInfo] = React.useState(defaultState);

    React.useEffect(() => {
        if (
            location.pathname.includes('/admin') ||
            location.pathname.includes('/login')
        ) {
            return;
        }
        setPageInfo(getPageInformation(i18n, location.pathname));
    }, [i18n, location]);

    const theme = useTheme();

    return (
        <HelmetContext.Provider value={pageInfo}>
            <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="msapplication-TileColor"
                    content={theme.palette.colors.darkGreen00}
                />
                <meta
                    name="theme-color"
                    content={theme.palette.colors.darkGreen00}
                ></meta>
                <title>{pageInfo.title}</title>
                <link rel="canonical" href={pageInfo.canonical} />
                <meta name="description" content={pageInfo.description} />
                <meta name="keywords" content={pageInfo.keywords} />
                <meta property="og:url" content={pageInfo.canonical} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={pageInfo.title} />
                <meta
                    property="og:description"
                    content={pageInfo.description}
                />
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="eaep-glyfada.gr" />
                <meta property="twitter:url" content={pageInfo.canonical} />
                <meta name="twitter:title" content={pageInfo.title} />
                <meta
                    name="twitter:description"
                    content={pageInfo.description}
                />
            </Helmet>
            {children}
        </HelmetContext.Provider>
    );
}
