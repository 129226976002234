import React from 'react';
import DraggableVideoPlayer from '../generic/VideoPlayer';

export const VideoPlayerContext = React.createContext();

export const VideoPlayerProvider = ({ children }) => {
    const videoPlayerRef = React.useRef();

    const openVideoPlayer = React.useCallback((title, videoUrl) => {
        videoPlayerRef.current.showVideoPlayer(title, videoUrl);
    }, []);

    const values = React.useMemo(() => {
        return {
            openVideoPlayer
        };
    }, [openVideoPlayer]);

    return (
        <VideoPlayerContext.Provider value={values}>
            {children}
            <DraggableVideoPlayer ref={videoPlayerRef} />
        </VideoPlayerContext.Provider>
    );
};
