import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: 20
    },
    title: {
        width: '100%',
        flexShrink: 0
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
}));

export default function AccordionComponent({ isOpen, title, children }) {
    const [isExpanded, setisExpanded] = React.useState(isOpen || false);

    const onToggle = React.useCallback(() => {
        setisExpanded(prev => !prev);
    }, []);

    const classes = useStyles();

    return (
        <Accordion
            expanded={isExpanded}
            onChange={onToggle}
            className={classes.root}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6" className={classes.title}>
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.content}>
                {children}
            </AccordionDetails>
        </Accordion>
    );
}
