import { Box, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Speaker, SPEAKERS_QUERY_KEY } from '../../firebase/';
import { GenericDialog, OptionSelector, ProgressButton, TextFieldInput } from '../../generic';
import { useAlert, useLanguage, useValidation } from '../../hooks';
import { trimValues } from '../../utils';

const defaultValues = {
    id: '',
    firstname: '',
    lastname: '',
    lang: ''
};

const useStyles = makeStyles(theme => ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: 250,
        justifyContent: 'space-evenly'
    },
    textField: {
        width: '100%'
    }
}));

const SpeakerDialog = ({ _ }, ref) => {
    const [values, setValues] = React.useState(defaultValues);
    const genericDialogRef = React.useRef();
    const { i18n } = useLanguage();
    const { openAlert } = useAlert();

    const { validateValues, ifError, errorMessages, resetValidation } =
        useValidation(['firstname', 'lastname', 'lang']);

    const queryClient = useQueryClient();

    const { isLoading, mutateAsync } = useMutation(
        ({ id, firstname, lastname, lang }) => {
            if (id) {
                return new Speaker({
                    firstname,
                    lastname,
                    lang
                })
                    .actions()
                    .editDocument(id);
            }
            return new Speaker({
                firstname,
                lastname,
                lang
            })
                .actions()
                .addDocument();
        },
        { onSuccess: () => queryClient.invalidateQueries(SPEAKERS_QUERY_KEY) }
    );

    const onChange = React.useCallback(
        (event, selectedValue, optionName) => {
            resetValidation();
            const { name, value } = event.target;

            if (optionName) {
                setValues(prevValues => ({
                    ...prevValues,
                    [optionName]: selectedValue
                }));
                return;
            }

            setValues(prevValues => ({
                ...prevValues,
                [name]: value
            }));
        },
        [resetValidation]
    );

    const openDialog = React.useCallback(() => {
        genericDialogRef.current.open();
    }, []);

    const closeDialog = React.useCallback(() => {
        resetValidation();
        setValues(defaultValues);
        genericDialogRef.current.close();
    }, [resetValidation]);

    React.useImperativeHandle(ref, () => ({
        openDialog: values => {
            if (values) {
                setValues(values);
            }
            openDialog();
        }
    }));

    const onSubmitValues = React.useCallback(async () => {
        if (!validateValues(values)) {
            return;
        }
        try {
            await mutateAsync(trimValues(values));
            closeDialog();
        } catch (error) {
            openAlert(error.name, error.message, true);
        }
    }, [values, validateValues, mutateAsync, openAlert, closeDialog]);

    const classes = useStyles();

    return (
        <GenericDialog
            onClose={closeDialog}
            ref={genericDialogRef}
            submitButton={
                <ProgressButton isLoading={isLoading} onClick={onSubmitValues}>
                    {i18n.genericDialog.adminDialogs.submit}
                </ProgressButton>
            }
            title={
                values.id
                    ? i18n.genericDialog.adminDialogs.editSpeakerTitle
                    : i18n.genericDialog.adminDialogs.addSpeakerTitle
            }
        >
            <Box className={classes.formContainer}>
                <TextFieldInput
                    name="lastname"
                    label={i18n.genericDialog.adminDialogs.lastname}
                    value={values.lastname}
                    onChange={onChange}
                    className={classes.textField}
                    error={ifError('lastname')}
                    helperText={errorMessages('lastname')}
                />
                <TextFieldInput
                    name="firstname"
                    label={i18n.genericDialog.adminDialogs.firstname}
                    value={values.firstname}
                    onChange={onChange}
                    className={classes.textField}
                    error={ifError('firstname')}
                    helperText={errorMessages('firstname')}
                />
                <OptionSelector
                    TextFieldComponent={TextField}
                    name="lang"
                    label={i18n.genericDialog.adminDialogs.lang}
                    value={values.lang}
                    onChange={onChange}
                    className={classes.textField}
                    error={ifError('lang')}
                    helperText={errorMessages('lang')}
                    langDataOptions={['en', 'el']}
                />
            </Box>
        </GenericDialog>
    );
};

export default React.forwardRef(SpeakerDialog);
