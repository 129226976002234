import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    link: {
        color: `${theme.palette.colors.white} !important`,
        textDecoration: 'none !important'
    }
}));

export default function CustomLink({ customClass, to, children }) {
    const classes = useStyles();
    return (
        <Link
            to={to}
            className={clsx({
                [classes.link]: !customClass,
                [customClass]: customClass
            })}
        >
            {children}
        </Link>
    );
}
