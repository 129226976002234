import React from 'react';
import { DialogContent, DialogContentText } from '@mui/material';
import { GenericDialog, ProgressButton } from '../generic';
import { useLanguage } from '../hooks';

export default React.forwardRef(function ContactFeedbackDialog({ _ }, ref) {
    const genericDialogRef = React.useRef();
    const { i18n } = useLanguage();

    const closeDialog = React.useCallback(() => {
        genericDialogRef.current.close();
    }, []);

    React.useImperativeHandle(ref, () => ({
        openDialog: () => genericDialogRef.current.open()
    }));

    return (
        <GenericDialog
            ref={genericDialogRef}
            onClose={closeDialog}
            title={i18n.genericDialog.contactFeedbackDialog.title}
            submitButton={
                <ProgressButton onClick={closeDialog}>Ok</ProgressButton>
            }
        >
            <DialogContent>
                <DialogContentText>
                    {i18n.genericDialog.contactFeedbackDialog.text}
                </DialogContentText>
            </DialogContent>
        </GenericDialog>
    );
});
