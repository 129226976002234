import { makeStyles } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { SPEAKERS_QUERY_KEY, Speaker } from '../../firebase';
import { DataGridDropdownMenu } from '../../admin';

const columns = [
    {
        field: 'lastname',
        headerName: 'Lastname',
        flex: 1,
        sortable: true
    },
    {
        field: 'firstname',
        headerName: 'Firstname',
        flex: 1,
        sortable: true
    },
    {
        field: 'fullname',
        headerName: 'Fullname',
        flex: 1,
        sortable: true
    },
    {
        field: 'lang',
        headerName: 'Language',
        flex: 1,
        sortable: true
    },
    {
        field: '',
        headerName: 'Actions',
        sortable: false,
        width: 100,
        disableClickEventBubbling: true,
        renderCell: params => {
            const onDelete = () => {
                return new Speaker().actions().deleteDocument(params.row.id);
            };

            return (
                <DataGridDropdownMenu
                    id={params.row.id}
                    rowData={params.row}
                    onDelete={onDelete}
                    mutationQueryKey={SPEAKERS_QUERY_KEY}
                    isEditingFor="speaker"
                />
            );
        }
    }
];

const useStyles = makeStyles(theme => ({
    dataGridRoot: {
        marginTop: 20
    }
}));

export default function SpeakersDataGrid({ loading, data }) {
    const [pageSize, setPageSize] = React.useState(5);
    const classes = useStyles();

    const rows = React.useMemo(() => {
        if (!data) {
            return [];
        }
        return data;
    }, [data]);

    return (
        <DataGrid
            classes={{ root: classes.dataGridRoot }}
            columns={columns}
            rows={rows}
            loading={loading}
            autoHeight
            autoPageSize
            pageSize={pageSize}
            rowsPerPageOptions={[5, 10, 25]}
            onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        />
    );
}
