import {
    Close as CloseIcon,
    Crop54 as MaximizeIcon,
    Minimize as MinimizeIcon
} from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Draggable from 'react-draggable';
import ReactPlayer from 'react-player';

const defaultDimensions = {
    height: 180,
    width: 180
};

const defaultPlayerInfo = {
    title: '',
    videoUrl: ``
};

const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        top: 100,
        right: 30,
        display: ({ isShown }) => (isShown ? 'block' : 'none'),
        zIndex: 1350,
        width: ({ dimensions: { width } }) => (width ? width : '100%'),
        height: ({ dimensions: { height } }) => (height ? height : '80vh'),
        backgroundColor: theme.palette.colors.darkGreen00
    },
    icons: {
        color: theme.palette.colors.lightGrey
    }
}));

const VideoPlayer = (props, ref) => {
    const [isShown, setIsShown] = React.useState(false);
    const [playerInfo, setPlayerInfo] = React.useState(defaultPlayerInfo);
    const [dimensions, setDimensions] = React.useState(defaultDimensions);

    const onShow = React.useCallback(
        (title, videoUrl) => {
            setPlayerInfo({ title, videoUrl });
            setIsShown(true);
        },
        [setPlayerInfo]
    );

    const onHide = React.useCallback(() => {
        setDimensions(defaultDimensions);
        setPlayerInfo(defaultPlayerInfo);
        localStorage.removeItem('videoTitle');
        localStorage.removeItem('videoUrl');
        setIsShown(false);
    }, []);

    const minimize = React.useCallback(() => {
        setDimensions(defaultDimensions);
    }, []);

    const maximize = React.useCallback(() => {
        setDimensions({
            height: '80vh',
            width: '100%'
        });
    }, []);

    React.useImperativeHandle(ref, () => ({
        showVideoPlayer: (title, videoUrl) => {
            onShow(title, videoUrl);
            localStorage.setItem('videoTitle', title);
            localStorage.setItem('videoUrl', videoUrl);
        }
    }));

    React.useEffect(() => {
        const title = localStorage.getItem('videoTitle');
        const videoUrl = localStorage.getItem('videoUrl');
        if (title || videoUrl) {
            setPlayerInfo({
                title,
                videoUrl
            });
            setIsShown(true);
        }
    }, []);

    const classes = useStyles({ dimensions, isShown });

    return (
        <Draggable
            axis="both"
            handle=".handle"
            grid={[10, 10]}
            scale={1}
            defaultClassName={classes.root}
        >
            <Box className="handle">
                <Box display="flex" justifyContent="flex-end">
                    <IconButton
                        variant="outlined"
                        onClick={minimize}
                        onTouchStart={minimize}
                    >
                        <MinimizeIcon className={classes.icons} />
                    </IconButton>
                    <IconButton
                        variant="outlined"
                        onClick={maximize}
                        onTouchStart={maximize}
                    >
                        <MaximizeIcon className={classes.icons} />
                    </IconButton>
                    <IconButton
                        variant="outlined"
                        onClick={onHide}
                        onTouchStart={onHide}
                    >
                        <CloseIcon className={classes.icons} />
                    </IconButton>
                </Box>
                <ReactPlayer
                    width="100%"
                    height="100%"
                    url={playerInfo.videoUrl}
                    controls={true}
                />
            </Box>
        </Draggable>
    );
};

export default React.forwardRef(VideoPlayer);
