import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import links from '../../links';
import { Logo } from '../generic';
import CustomLink from '../generic/CustomLink';
import { useLanguage } from '../hooks';
import { DropDownMenu, LanguageSwitch } from '../navigationBar';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between'
    },
    linksContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        flex: 1,
        maxWidth: 800
    },
    customColorClass: {
        color: `${theme.palette.colors.lightGrey} !important`
    },
    dropdownLinks: {
        color: `${theme.palette.primary.main} !important`,
        textDecoration: 'none !important'
    },
    links: {
        color: `${theme.palette.colors.lightGrey} !important`,
        textDecoration: 'none !important'
    }
}));

export default function DesktopLinks() {
    const { i18n } = useLanguage();

    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Logo />
            <Box className={classes.linksContainer}>
                <CustomLink to={links.home} customClass={classes.links}>
                    {i18n.navigation.homepage}
                </CustomLink>
                <DropDownMenu
                    id={i18n.navigation.aboutUs}
                    title={i18n.navigation.aboutUs}
                    customIconClass={classes.customColorClass}
                    customDropdownButtonClass={classes.customColorClass}
                >
                    <CustomLink
                        to={links.ourHistory}
                        customClass={classes.dropdownLinks}
                    >
                        {i18n.navigation.aboutUsSublinks.ourHistory}
                    </CustomLink>
                    <CustomLink
                        to={links.whatWeBelieve}
                        customClass={classes.dropdownLinks}
                    >
                        {i18n.navigation.aboutUsSublinks.whatWeBelieve}
                    </CustomLink>
                </DropDownMenu>
                <DropDownMenu
                    id={i18n.navigation.church}
                    title={i18n.navigation.church}
                    customIconClass={classes.customColorClass}
                    customDropdownButtonClass={classes.customColorClass}
                >
                    <CustomLink
                        to={links.churchService}
                        customClass={classes.dropdownLinks}
                    >
                        {i18n.navigation.churchSublinks.churchService}
                    </CustomLink>
                    <CustomLink
                        to={links.youth}
                        customClass={classes.dropdownLinks}
                    >
                        {i18n.navigation.churchSublinks.youth}
                    </CustomLink>
                </DropDownMenu>
                <CustomLink to={links.sermons} customClass={classes.links}>
                    {i18n.navigation.sermons}
                </CustomLink>
                <CustomLink to={links.contact} customClass={classes.links}>
                    {i18n.navigation.contact}
                </CustomLink>
                <LanguageSwitch customLabelClass={classes.customColorClass} />
            </Box>
        </Box>
    );
}
