const HOME_LINK = '/';
const OUR_HISTORY_LINK = '/our-history';
const WHAT_WE_BELIEVE_LINK = '/what-we-believe';
const CHURCH_SERVICE_LINK = '/church-service';
const YOUTH_LINK = '/youth';
const SERMON_LINK = '/sermons';
const CONTACT_LINK = '/contact';
const LOGIN_LINK = '/login';
const ADMIN_LINK = '/admin';
const ADMIN_SPEAKERS_LINK = '/admin/speakers';
const ADMIN_CONTACT_INFO_LINK = '/admin/contact-info';
const ADMIN_BROADCAST_LINK = '/admin/broadcast';
const ADMIN_PLAYLIST_LINK = '/admin/playlist';

const links = {
    home: HOME_LINK,
    ourHistory: OUR_HISTORY_LINK,
    whatWeBelieve: WHAT_WE_BELIEVE_LINK,
    churchService: CHURCH_SERVICE_LINK,
    youth: YOUTH_LINK,
    sermons: SERMON_LINK,
    contact: CONTACT_LINK,
    login: LOGIN_LINK,
    admin: ADMIN_LINK,
    adminSpeakers: ADMIN_SPEAKERS_LINK,
    adminContactInfo: ADMIN_CONTACT_INFO_LINK,
    adminBroadcast: ADMIN_BROADCAST_LINK,
    adminPlaylist: ADMIN_PLAYLIST_LINK
};

export default links;
