import { Autocomplete, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        width: '100%'
    },
    autocomplete: {
        width: '100%'
    },
    labelFocused: {
        color: `${theme.palette.colors.darkGreen00} !important`
    }
}));

export default function OptionSelector({
    TextFieldComponent,
    onChange,
    name,
    label,
    value,
    error,
    helperText,
    className,
    containerClass,
    langDataOptions
}) {
    const classes = useStyles();

    return (
        <Box className={clsx(classes.container, containerClass)}>
            <Autocomplete
                disablePortal
                options={langDataOptions}
                classes={{ root: clsx(classes.autocomplete, className) }}
                getOptionLabel={option => option}
                onChange={(e, v) => onChange(e, v, name)}
                value={value}
                renderInput={params => (
                    <TextFieldComponent
                        label={label}
                        error={error}
                        helperText={helperText}
                        {...params}
                        InputLabelProps={{
                            classes: { focused: classes.labelFocused }
                        }}
                    />
                )}
            />
        </Box>
    );
}
