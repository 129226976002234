import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';
import { useLanguage } from '../hooks';

const useStyles = makeStyles(theme => ({
    label: {
        color: theme.palette.colors.white,
        [theme.breakpoints.down('md')]: {
            color: theme.palette.primary.main
        }
    },
    switch: {
        width: 62,
        height: 34,
        padding: 7
    },
    switchBase: {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        color: `${theme.palette.colors.lightGrey} !important`,
        [theme.breakpoints.down('lg')]: {
            color: `${theme.palette.primary.main} !important`
        },
        '&.Mui-checked': {
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                content: ({ el, eng, lang }) =>
                    lang === 'el' ? `${el}` : `${eng}`,
                fontsize: 8,
                color: `${theme.palette.warning.main}`,
                position: 'absolute',
                top: 7,
                left: 9
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: `${theme.palette.colors.lightGrey} !important`,
                [theme.breakpoints.down('lg')]: {
                    backgroundColor: `${theme.palette.primary.main} !important`
                }
            }
        }
    },
    checked: {
        color: theme.palette.colors.white,
        transform: 'translateX(22px)'
    },
    thumb: {
        width: 32,
        height: 32,
        '&:before': {
            content: ({ el, eng, lang }) =>
                lang === 'en' ? `${eng}` : `${el}`,
            color: `${theme.palette.warning.main}`,
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 7,
            left: 7
        }
    },
    track: {
        color: `${theme.palette.colors.lightGrey} !important`,
        opacity: 1,
        borderRadius: 20 / 2
    }
}));

export default function LanguageSwitch({ customClass, customLabelClass }) {
    const { i18n, lang, toggleLangEl, toggleLangEn } = useLanguage();

    const classes = useStyles({
        lang,
        el: i18n.navigation.switchLang.el,
        eng: i18n.navigation.switchLang.eng
    });

    const toggleLang = React.useCallback(() => {
        if (lang === 'el') {
            toggleLangEn();
        } else {
            toggleLangEl();
        }
    }, [lang, toggleLangEl, toggleLangEn]);

    return (
        <FormGroup classes={{ root: clsx(customClass) }}>
            <FormControlLabel
                classes={{ label: clsx(classes.label, customLabelClass) }}
                control={
                    <Switch
                        classes={{
                            root: classes.switch,
                            switchBase: classes.switchBase,
                            checked: classes.checked,
                            thumb: classes.thumb,
                            track: classes.track
                        }}
                        onChange={toggleLang}
                    />
                }
                label={i18n.navigation.lang}
            />
        </FormGroup>
    );
}
