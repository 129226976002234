import {
    ArrowDropDown as ArrowDropDownIcon,
    ArrowDropUp as ArrowDropUpIcon
} from '@mui/icons-material/';
import { Button, Menu, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(theme => ({
    dropdownMenuButton: {
        display: 'flex',
        color: `${theme.palette.colors.lightGrey} !important`,
        textTransform: 'capitalize !important',
        padding: '0px !important',
        fontWeight: '400 !important',
        fontSize: '1rem !important',
        lineHeight: '1.5 !important',
        letterSpacing: '0.00938em !important',
        [theme.breakpoints.down('lg')]: {
            color: `${theme.palette.primary.main} !important`
        }
    },
    menu: {
        '& ul': {
            background: theme.palette.colors.white,
            padding: 10
        }
    },
    childLinks: {
        background: `${theme.palette.colors.white} !important`,
        '& :hover': {
            background: `${theme.palette.colors.white} !important`
        }
    },
    icon: {
        color: theme.palette.colors.white,
        textDecoration: 'none',
        [theme.breakpoints.down('lg')]: {
            color: `${theme.palette.primary.main} !important`
        }
    }
}));

export default function DropDownMenu({
    id,
    title,
    customMenuClass,
    customIconClass,
    customDropdownButtonClass,
    children
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);

    const onOpen = React.useCallback(event => {
        setAnchorEl(event.currentTarget);
    }, []);

    const onClose = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const classes = useStyles();

    const renderChildren = React.Children.map(children, child => (
        <MenuItem className={classes.childLinks}>
            {React.cloneElement(child)}
        </MenuItem>
    ));

    const renderIcon = React.useMemo(() => {
        return open ? (
            <ArrowDropDownIcon
                className={clsx(classes.icon, customIconClass)}
            />
        ) : (
            <ArrowDropUpIcon className={clsx(classes.icon, customIconClass)} />
        );
    }, [open, classes.icon, customIconClass]);

    return (
        <>
            <Button
                id={`${id}-button`}
                aria-controls={`${id}-menu`}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={onOpen}
                className={clsx(
                    classes.dropdownMenuButton,
                    customDropdownButtonClass
                )}
                disableFocusRipple
                disableTouchRipple
                disableRipple
            >
                {title}
                {renderIcon}
            </Button>
            <Menu
                id={`${id}-menu`}
                anchorEl={anchorEl}
                open={open}
                onClose={onClose}
                MenuListProps={{
                    'aria-labelledby': `${id}-button`
                }}
                className={clsx({
                    [classes.menu]: !customMenuClass,
                    [customMenuClass]: customMenuClass
                })}
            >
                {renderChildren}
            </Menu>
        </>
    );
}
