import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { CustomLink } from '../generic';

const useStyles = makeStyles(theme => ({
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap'
    },
    link: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.warning.main,
        textDecoration: 'none'
    }
}));

export default function SideDrawerLink({ icon, to, children }) {
    const classes = useStyles();
    return (
        <ListItem key={children}>
            <CustomLink customClass={classes.link} to={to}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={children} />
            </CustomLink>
        </ListItem>
    );
}
