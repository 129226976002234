import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import useLanguage from '../../hooks/useLanguage';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'space-around',
        marginBottom: 10
    },
    button: {
        textTransform: 'capitalize !important',
        marginLeft: '20px !important'
    }
});

export default function GenericDialogActions({ close, children }) {
    const { i18n } = useLanguage();
    const classes = useStyles();
    return (
        <DialogActions className={classes.root}>
            {children}
            <Button
                variant="contained"
                color="error"
                onClick={close}
                className={classes.button}
            >
                {i18n.genericDialog.adminDialogs.cancel}
            </Button>
        </DialogActions>
    );
}
