import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Broadcast, BROADCAST_QUERY_KEY } from '../../firebase';
import {
    Datepicker,
    GenericDialog,
    ProgressButton,
    TextFieldInput
} from '../../generic';
import { useAlert, useLanguage, useValidation } from '../../hooks';

const defaultValues = {
    id: '',
    videoId: '',
    date: new Date()
};

const useStyles = makeStyles(theme => ({
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: 200,
        justifyContent: 'space-evenly'
    },
    textField: {
        width: '100%'
    }
}));

const BroadcastDialog = ({ _ }, ref) => {
    const [broadcastValues, setBroadcastValues] = React.useState(defaultValues);
    const genericDialogRef = React.useRef();
    const { i18n, lang } = useLanguage();
    const { openAlert } = useAlert();

    const { validateValues, ifError, errorMessages, resetValidation } =
        useValidation(['videoId', 'date']);

    const queryClient = useQueryClient();

    const { isLoading, mutateAsync } = useMutation(
        () => {
            const { videoId, date } = broadcastValues;
            if (broadcastValues.id) {
                return new Broadcast({
                    videoId,
                    lang,
                    date
                })
                    .actions()
                    .editDocument(broadcastValues.id);
            }

            return new Broadcast({
                videoId,
                lang,
                date
            })
                .actions()
                .addDocument();
        },
        { onSuccess: () => queryClient.invalidateQueries(BROADCAST_QUERY_KEY) }
    );

    const onChange = React.useCallback(
        (event, selectedValue, optionName) => {
            resetValidation();
            const { name, value } = event.target;

            if (optionName) {
                setBroadcastValues(prevValues => ({
                    ...prevValues,
                    [optionName]: selectedValue
                }));
                return;
            }

            setBroadcastValues(prevValues => ({
                ...prevValues,
                [name]: value
            }));
        },
        [resetValidation]
    );

    const openDialog = React.useCallback(() => {
        genericDialogRef.current.open();
    }, []);

    const closeDialog = React.useCallback(() => {
        resetValidation();
        setBroadcastValues(defaultValues);
        genericDialogRef.current.close();
    }, [resetValidation]);

    React.useImperativeHandle(ref, () => ({
        openDialog: values => {
            if (values) {
                setBroadcastValues({ ...values, date: new Date(values.date) });
            }
            openDialog();
        }
    }));

    const onSubmitValues = React.useCallback(async () => {
        const { videoId, lang, date } = broadcastValues;
        if (
            !validateValues({
                videoId,
                lang,
                date
            })
        ) {
            return;
        }
        try {
            await mutateAsync();
            closeDialog();
        } catch (error) {
            openAlert(error.name, error.message, true);
        }
    }, [broadcastValues, validateValues, mutateAsync, openAlert, closeDialog]);

    const classes = useStyles();

    return (
        <GenericDialog
            onClose={closeDialog}
            ref={genericDialogRef}
            submitButton={
                <ProgressButton isLoading={isLoading} onClick={onSubmitValues}>
                    {i18n.genericDialog.adminDialogs.submit}
                </ProgressButton>
            }
            title={
                broadcastValues.id
                    ? i18n.genericDialog.adminDialogs.broadcastEditTitle
                    : i18n.genericDialog.adminDialogs.broadcastTitle
            }
        >
            <Box className={classes.formContainer}>
                <TextFieldInput
                    name="videoId"
                    label={i18n.genericDialog.adminDialogs.videoId}
                    value={broadcastValues.videoId}
                    onChange={onChange}
                    className={classes.textField}
                    error={ifError('videoId')}
                    helperText={errorMessages('videoId')}
                />
                <Datepicker
                    name="date"
                    value={broadcastValues.date}
                    label={i18n.genericDialog.adminDialogs.date}
                    setValues={setBroadcastValues}
                    className={classes.textField}
                    fullWidth
                    error={ifError('date')}
                    helperText={errorMessages('date')}
                />
            </Box>
        </GenericDialog>
    );
};

export default React.forwardRef(BroadcastDialog);
