import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import GenericDialogActions from './GenericDialogActions';

const useStyles = makeStyles(theme => ({
    dialogRoot: {
        padding: 20,
        width: '100%',
        maxWidth: 600
    }
}));

const GenericDialog = ({ title, submitButton, onClose, children }, ref) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const open = React.useCallback(() => {
        setIsOpen(true);
    }, []);

    const close = React.useCallback(() => {
        setIsOpen(false);
    }, []);

    React.useImperativeHandle(ref, () => ({
        open,
        close
    }));

    const classes = useStyles();

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            scroll="paper"
            classes={{ paper: classes.dialogRoot }}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <GenericDialogActions close={onClose}>
                {submitButton}
            </GenericDialogActions>
        </Dialog>
    );
};

export default React.forwardRef(GenericDialog);
