import * as R from 'ramda';
import { format } from 'date-fns';

export const trimValues = values => {
    if (typeof values === 'object') {
        const trimmedValuesObject = { ...values };
        for (const [key, value] of Object.entries(trimmedValuesObject)) {
            if (typeof value === 'string') {
                trimmedValuesObject[key] = R.trim(value);
            }
        }

        return trimmedValuesObject;
    }
};

export const capitalizeString = string => {
    if (typeof string !== 'string') {
        const error = new Error(
            `The input must be a string and you passed ${typeof string}`
        );
        error.name = 'WRONG TYPE OF PARAMETER PASSED';
        throw error;
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatDate = (date, formatAsNormalDate) => {
    if (formatAsNormalDate) {
        return format(date, 'dd/MM/yyyy');
    }
    return format(date, 'dd/MM/yyyy k:mm:s a');
};

export const isEqual = (a, b) => {
    return R.equals(a, b);
};

export const createAnObjectBasedOnValues = obj => {
    const filteredObject = {};
    const entries = Object.entries(obj);
    for (const [key, value] of entries) {
        if (value) {
            filteredObject[key] = value;
        }
    }

    return filteredObject;
};

export const compareObjectEqualityByValues = (data, entries, targetObject) => {
    const objectToCompare = {};
    for (const [key, value] of entries) {
        if (data[key] === value) {
            objectToCompare[key] = value;
        }
    }
    return R.equals(targetObject, objectToCompare);
};

export const firebaseStructuredObject = (id, object) => {
    const structuredObject = { id };
    const entries = Object.entries(object);

    for (const [key, value] of entries) {
        if (key === 'date') {
            structuredObject[key] = formatDate(value.toDate(), true);
        } else {
            structuredObject[key] = value;
        }
    }

    return structuredObject;
};

export const isObjectEmpty = object => {
    return R.isEmpty(object);
};

export const getDay = () => {
    const days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ];
    var now = new Date();
    return days[now.getDay()];
};
