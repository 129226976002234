import { DialogContent, DialogContentText } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import React from 'react';
import { GenericDialog, ProgressButton } from '../../generic';
import { useLanguage } from '../../hooks';

const ConfirmDialog = ({ _ }, ref) => {
    const [info, setInfo] = React.useState({
        action: () => {},
        queryKey: null,
        id: null
    });
    const genericDialogRef = React.useRef();
    const { i18n } = useLanguage();
    const queryClient = useQueryClient();

    const action = React.useCallback(() => {
        info.action();
        genericDialogRef.current.close();
    }, [info]);

    const closeDialog = React.useCallback(() => {
        genericDialogRef.current.close();
    }, []);

    React.useImperativeHandle(ref, () => ({
        openDialog: (action, queryKey, id) => {
            setInfo({ action, queryKey, id });
            genericDialogRef.current.open();
        }
    }));

    const { mutateAsync: onAction, isLoading } = useMutation(() => action(), {
        onSuccess: () => queryClient.invalidateQueries(info.queryKey)
    });

    return (
        <GenericDialog
            onClose={closeDialog}
            ref={genericDialogRef}
            title={i18n.genericDialog.adminDialogs.confirmTitle}
            submitButton={
                <ProgressButton onClick={onAction} isLoading={isLoading}>
                    {i18n.genericDialog.adminDialogs.confirmButton}
                </ProgressButton>
            }
        >
            <DialogContent>
                <DialogContentText>
                    {i18n.genericDialog.adminDialogs.confirmMessage(info.id)}
                </DialogContentText>
            </DialogContent>
        </GenericDialog>
    );
};

export default React.forwardRef(ConfirmDialog);
