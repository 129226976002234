import React from 'react';
import { el, en } from '../../locale';

export const LanguageContext = React.createContext();

// function getLanguage() {
//     if (typeof window === 'undefined') {
//         return 'en';
//     }
//     return navigator.language === 'el' ? 'el' : 'en';
// }

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = React.useState('el');
    const [previousLang, setPreviousLang] = React.useState('el');

    const i18n = React.useMemo(() => {
        return language === 'el' ? el : en;
    }, [language]);

    const toggleLangEl = React.useCallback(() => {
        setLanguage('el');
        setPreviousLang('en');
    }, []);

    const toggleLangEn = React.useCallback(() => {
        setLanguage('en');
        setPreviousLang('el');
    }, []);

    const values = React.useMemo(() => {
        return {
            i18n,
            lang: language,
            toggleLangEl,
            toggleLangEn,
            previousLang
        };
    }, [language, i18n, toggleLangEl, toggleLangEn, previousLang]);

    return (
        <LanguageContext.Provider value={values}>
            {children}
        </LanguageContext.Provider>
    );
};
