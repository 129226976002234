import { Timestamp } from '@firebase/firestore';
import { ORDER_BY_DATE, SERMONS_COLLECTION } from './constants';
import { DatabaseActions } from '../index';
export default class Sermon {
    constructor(props) {
        this.data = props && props;
    }

    thumbnailUrlFormatter = () => {
        const index = this.data.link.indexOf('v=');
        const videoId = this.data.link.slice(index + 2);
        return (this.data.thumbnail = `https://img.youtube.com/vi/${videoId}/0.jpg`);
    };

    timestampFormatter = () => {
        const date = this.data.date;
        return (this.data.date = Timestamp.fromDate(date));
    };

    getState = () => {
        if (!this.data) {
            return null;
        }
        return {
            ...this.data,
            thumbnail: this.thumbnailUrlFormatter(),
            date: this.timestampFormatter()
        };
    };

    actions = () =>
        new DatabaseActions({
            data: this.getState(),
            collection: SERMONS_COLLECTION,
            order: ORDER_BY_DATE
        });
}
