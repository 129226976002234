import { Close as CloseIcon } from '@mui/icons-material';
import { Alert, AlertTitle, Box, Collapse, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flex: 1
    },
    collapse: {
        padding: '0px 200px',
        width: '100%',
        position: 'absolute',
        top: '70%',
        zIndex: 1
    }
});

const NotifyUserAlert = ({ _ }, ref) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const [message, setMessage] = React.useState({
        title: '',
        context: ''
    });

    const open = React.useCallback(() => {
        setIsOpen(true);
    }, []);

    const close = React.useCallback(() => {
        setIsOpen(false);
    }, []);

    React.useImperativeHandle(ref, () => ({
        open: (title, context, isError) => {
            setMessage({ title, context });
            setIsError(isError);
            open();
        }
    }));

    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Collapse in={isOpen} className={classes.collapse}>
                <Alert
                    severity={isError ? 'error' : 'success'}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={close}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                >
                    <AlertTitle>{message.title}</AlertTitle>
                    {message.context}
                </Alert>
            </Collapse>
        </Box>
    );
};

export default React.forwardRef(NotifyUserAlert);
