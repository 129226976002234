import { auth } from '../firebase';
import {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    confirmPasswordReset
} from 'firebase/auth';
import React from 'react';

export const AuthenticationContext = React.createContext();

export function AuthenticationProvider({ children }) {
    const [user, setUser] = React.useState(null);
    const [isAuthenticated, setIsAuthenticated] = React.useState(true);

    const signIn = React.useCallback(async (email, password) => {
        const response = await signInWithEmailAndPassword(
            auth,
            email,
            password
        );
        setUser(response.user);
        setIsAuthenticated(true);
    }, []);

    const signUp = React.useCallback(async (email, password) => {
        return await createUserWithEmailAndPassword(auth, email, password);
    }, []);

    const signOutUser = React.useCallback(async () => {
        return await auth.signOut();
    }, []);

    const sendPasswordResetUserEmail = React.useCallback(async email => {
        return await sendPasswordResetEmail(auth, email);
    }, []);

    const confirmPasswordUserReset = React.useCallback(
        async (code, password) => {
            return await confirmPasswordReset(auth, code, password);
        },
        []
    );

    React.useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                setUser(user);
                setIsAuthenticated(true);
            } else {
                setUser(null);
                setIsAuthenticated(false);
            }
        });

        return () => unsubscribe();
    }, []);

    const values = React.useMemo(() => {
        return {
            isAuthenticated,
            user,
            signIn,
            signUp,
            signOutUser,
            sendPasswordResetUserEmail,
            confirmPasswordUserReset
        };
    }, [
        isAuthenticated,
        user,
        signIn,
        signUp,
        signOutUser,
        sendPasswordResetUserEmail,
        confirmPasswordUserReset
    ]);

    return (
        <AuthenticationContext.Provider value={values}>
            {children}
        </AuthenticationContext.Provider>
    );
}
