import {
    Book as BookIcon,
    Email as EmailIcon,
    LocalPhone as TelIcon,
    PhoneIphone as MobileIcon,
    Web as WebIcon,
    Map as MapIcon,
    Science as ScienceIcon,
    OndemandVideo as ChannelIcon
} from '@mui/icons-material';
import {
    Box,
    Container,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { CustomLink, SocialMedia } from '../generic';
import { useLanguage } from '../hooks';

const url = `https://www.google.com/maps/dir//Free+Apostolic+Church+of+Pentecost+Attikis+6+Glifada+165+62/@37.8948109,23.7616627,19z/data=!4m8!4m7!1m0!1m5!1m1!1s0x14a1be1d1378ad1f:0xb188d06a68c2b20d!2m2!1d23.7616627!2d37.8948109`;

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 100,
        background: theme.palette.colors.footer
    },
    container: {
        margin: '25px 0px'
    },
    containers: {
        padding: '25px 0px'
    },
    clickableLinks: {
        textDecoration: 'none',
        cursor: 'pointer',
        color: `${theme.palette.colors.white} !important`
    },
    titleList: {
        textDecoration: 'underline',
        color: `${theme.palette.colors.white} !important`
    },
    whiteText: {
        color: `${theme.palette.colors.white} !important`
    },
    copyrightText: {
        padding: 20
    }
}));

function Footer() {
    const { i18n } = useLanguage();
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <Container maxWidth="xl">
                <Grid container className={classes.container}>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        className={classes.containers}
                    >
                        <List>
                            <ListItem>
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant="h5"
                                            className={classes.titleList}
                                        >
                                            {i18n.footer.contact.title}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <MapIcon className={classes.whiteText} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={i18n.footer.contact.addressTitle}
                                    primaryTypographyProps={{
                                        className: classes.whiteText
                                    }}
                                    secondary={
                                        <a
                                            href={url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className={classes.clickableLinks}
                                        >
                                            {i18n.footer.contact.address}
                                        </a>
                                    }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <TelIcon className={classes.whiteText} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={i18n.footer.contact.telTitle}
                                    primaryTypographyProps={{
                                        className: classes.whiteText
                                    }}
                                    secondary={
                                        <a
                                            href={`tel: ${i18n.footer.contact.tel}`}
                                            className={classes.clickableLinks}
                                        >
                                            {i18n.footer.contact.tel}
                                        </a>
                                    }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <MobileIcon className={classes.whiteText} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={i18n.footer.contact.mobileTitle}
                                    primaryTypographyProps={{
                                        className: classes.whiteText
                                    }}
                                    secondary={
                                        <a
                                            href={`tel: ${i18n.footer.contact.mobile}`}
                                            className={classes.clickableLinks}
                                        >
                                            {i18n.footer.contact.mobile}
                                        </a>
                                    }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <EmailIcon className={classes.whiteText} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={i18n.footer.contact.emailTitle}
                                    primaryTypographyProps={{
                                        className: classes.whiteText
                                    }}
                                    secondary={
                                        <a
                                            href={`mailto: ${i18n.footer.contact.email}`}
                                            className={classes.clickableLinks}
                                        >
                                            {i18n.footer.contact.email}
                                        </a>
                                    }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <WebIcon className={classes.whiteText} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={i18n.footer.contact.websiteTitle}
                                    primaryTypographyProps={{
                                        className: classes.whiteText
                                    }}
                                    secondary={
                                        <CustomLink
                                            to="/"
                                            customClass={classes.clickableLinks}
                                        >
                                            {i18n.footer.contact.website}
                                        </CustomLink>
                                    }
                                />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        className={classes.containers}
                    >
                        <SocialMedia />
                    </Grid>
                    <Grid item xs={12} md={4} className={classes.containers}>
                        <List>
                            <ListItem>
                                <ListItemText
                                    primary={
                                        <Typography
                                            variant="h5"
                                            className={classes.titleList}
                                        >
                                            {i18n.footer.channels.title}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <ScienceIcon
                                        className={classes.whiteText}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        i18n.footer.channels.christianityTitle
                                    }
                                    primaryTypographyProps={{
                                        className: classes.whiteText
                                    }}
                                    secondary={
                                        <a
                                            href={`https://${i18n.footer.channels.christianity}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className={classes.clickableLinks}
                                        >
                                            {i18n.footer.channels.christianity}
                                        </a>
                                    }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <ChannelIcon
                                        className={classes.whiteText}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={i18n.footer.channels.facpTubeTitle}
                                    primaryTypographyProps={{
                                        className: classes.whiteText
                                    }}
                                    secondary={
                                        <a
                                            href={`https://${i18n.footer.channels.facpTube}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className={classes.clickableLinks}
                                        >
                                            {i18n.footer.channels.facpTube}
                                        </a>
                                    }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <ChannelIcon
                                        className={classes.whiteText}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        i18n.footer.channels.wordOfGodTitle
                                    }
                                    primaryTypographyProps={{
                                        className: classes.whiteText
                                    }}
                                    secondary={
                                        <a
                                            href={`https://${i18n.footer.channels.wordOfGod}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className={classes.clickableLinks}
                                        >
                                            {i18n.footer.channels.wordOfGod}
                                        </a>
                                    }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <ChannelIcon
                                        className={classes.whiteText}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        i18n.footer.channels.voiceOfGodTitle
                                    }
                                    primaryTypographyProps={{
                                        className: classes.whiteText
                                    }}
                                    secondary={
                                        <a
                                            href={`https://${i18n.footer.channels.voiceOfGod}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className={classes.clickableLinks}
                                        >
                                            {i18n.footer.channels.voiceOfGod}
                                        </a>
                                    }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <BookIcon className={classes.whiteText} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        i18n.footer.channels.imnologioTitle
                                    }
                                    primaryTypographyProps={{
                                        className: classes.whiteText
                                    }}
                                    secondary={
                                        <a
                                            href={`https://${i18n.footer.channels.imnologio}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className={classes.clickableLinks}
                                        >
                                            {i18n.footer.channels.imnologio}
                                        </a>
                                    }
                                />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default React.memo(Footer);
