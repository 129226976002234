import { Box, Card, CardActionArea, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Sermon } from '../firebase';
import { useLanguage, useVideoPlayer } from '../hooks';
import { EmptyContent } from '../generic';

const useStyles = makeStyles(theme => ({
    carouseItemClass: {
        padding: 20
    },
    carouselTitle: {
        padding: '50px 0px 30px',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center !important',
            width: '100%'
        }
    },
    opacityContainer: {
        background: theme.palette.colors.darkGreen00,
        position: 'absolute',
        height: `calc(100% - 75%)`,
        width: '100%',
        opacity: 0.6,
        bottom: 0,
        zIndex: 1,
        [theme.breakpoints.down('lg')]: {
            height: `calc(100% - 65%) !important`
        },
        [theme.breakpoints.down('md')]: {
            height: `calc(100% - 70%) !important`
        },
        [theme.breakpoints.down('sm')]: {
            height: `calc(100% - 65%) !important`
        }
    },
    description: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        position: 'absolute',
        height: `calc(100% - 75%)`,
        bottom: 0,
        zIndex: 2,
        [theme.breakpoints.down('lg')]: {
            height: `calc(100% - 65%) !important`
        },
        [theme.breakpoints.down('md')]: {
            height: `calc(100% - 70%) !important`
        },
        [theme.breakpoints.down('sm')]: {
            height: `calc(100% - 65%) !important`
        }
    },
    text: {
        textAlign: 'center',
        padding: '2px 0px 0px 0px',
        color: '#fff',
        lineHeight: 1.1
    }
}));

const carouselResponsiveSettings = {
    superLargeDesktop: {
        breakpoint: { max: 7000, min: 1200 },
        items: 4
    },
    desktop: {
        breakpoint: { max: 1200, min: 600 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 900, min: 600 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 600, min: 0 },
        items: 1
    }
};

function SermonCarouselItem({
    title,
    speakers,
    category,
    book,
    chapterAndVerce,
    link,
    thumbnail,
    page,
    date
}) {
    const classes = useStyles();

    const { openVideoPlayer } = useVideoPlayer();

    const openVideoDialog = React.useCallback(() => {
        openVideoPlayer(title, link);
    }, [openVideoPlayer, title, link]);

    const renderBook = React.useMemo(() => {
        if (book === '-' || chapterAndVerce === '-' || page === '-') {
            return null;
        }

        return (
            <Typography variant="caption" className={classes.text}>
                {book} - {chapterAndVerce} - ({page})
            </Typography>
        );
    }, [book, chapterAndVerce, page, classes]);

    return (
        <Card className={classes.itemRoot}>
            <CardActionArea position="relative" onClick={openVideoDialog}>
                <img
                    width="100%"
                    src={thumbnail}
                    alt={`${category} - ${book} - ${chapterAndVerce}`}
                />
                <Box className={classes.opacityContainer}></Box>
                <Box className={classes.description}>
                    <Typography variant="caption" className={classes.text}>
                        {title} - {speakers.join(', ')} - {category}
                    </Typography>
                    {renderBook}
                    <Typography variant="caption" className={classes.text}>
                        {date}
                    </Typography>
                </Box>
            </CardActionArea>
        </Card>
    );
}

export default React.memo(function SermonsCarousel() {
    const [sermons, setSermons] = React.useState([]);
    const { i18n, lang } = useLanguage();

    const getCarouselSermons = React.useCallback(async () => {
        const sermons = await new Sermon()
            .actions()
            .getDocumentsForClientWithLimit(lang, 8);
        setSermons(sermons);
    }, [lang]);

    React.useEffect(() => {
        getCarouselSermons();
    }, [getCarouselSermons, lang]);

    const classes = useStyles();

    if (!sermons.length) {
        return (
            <>
                <Typography
                    variant="h4"
                    align="center"
                    gutterBottom
                    className={classes.carouselTitle}
                >
                    {i18n.homePage.carousel.title}
                </Typography>
                <Box display="flex" justifyContent="center" width="100%">
                    <EmptyContent />
                </Box>
            </>
        );
    }

    return (
        <>
            <Typography
                variant="h4"
                align="center"
                gutterBottom
                className={classes.carouselTitle}
            >
                {i18n.homePage.carousel.title}
            </Typography>
            <Carousel
                className={classes.carousel}
                responsive={carouselResponsiveSettings}
                infinite={true}
                itemClass={classes.carouseItemClass}
                containerClass={classes.carouselContainerClass}
                autoPlay={true}
            >
                {sermons.map(sermon => (
                    <SermonCarouselItem
                        key={sermon.id}
                        title={sermon.title}
                        speakers={sermon.speakers}
                        category={sermon.category}
                        book={sermon.book}
                        chapterAndVerce={sermon.chapterAndVerce}
                        link={sermon.link}
                        thumbnail={sermon.thumbnail}
                        page={sermon.page}
                        date={sermon.date}
                    />
                ))}
            </Carousel>
        </>
    );
});
