import createTheme from '@mui/material/styles/createTheme';

const theme = createTheme({
    palette: {
        primary: {
            main: '#006064'
        },
        colors: {
            white: '#fff',
            darkGreen00: '#006064',
            lightGrey: '#d7f8ff',
            instagram: 'rgb(251, 57, 88)',
            facebook: '#4267B2',
            youTube: '#FF0000',
            footer: '#708090',
            copyright: '#696969',
            red: '#FF0000'
        }
    }
});

export default theme;
