import useLanguage from './useLanguage';
import React from 'react';

function validateEmail(value) {
    return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
        value
    );
}

function validatePhone(value) {
    return /^21.\d{7}|^69.\d{7}/.test(value);
}

function validatePassword(value) {
    return /^.{5,20}$/.test(value);
}

function validateMultiline(value) {
    return /^[a-zA-Z0-9 _.,!"']{20,80}$/.test(value);
}

export default function useValidation(validationSchemaValues) {
    const { i18n } = useLanguage();
    const [validationErrors, setValidationErrors] = React.useState({});
    const [validationMessages, setValidationMessages] = React.useState({});

    React.useEffect(() => {
        setValidationMessages({
            email: i18n.validation.email,
            password: i18n.validation.password,
            phone: i18n.validation.phone,
            multiline: i18n.validation.multiline,
            required: i18n.validation.required
        });
    }, [i18n]);

    const uiErrors = React.useMemo(() => {
        return !!Object.keys(validationErrors).length;
    }, [validationErrors]);

    const validateValues = React.useCallback(
        values => {
            let isValidationError = false;
            validationSchemaValues.forEach(schemaValue => {
                if (
                    schemaValue === 'email' &&
                    !validateEmail(values[schemaValue])
                ) {
                    setValidationErrors(values => ({
                        ...values,
                        email: true
                    }));
                    setValidationMessages(values => ({
                        ...values,
                        email: i18n.validation.email
                    }));
                    isValidationError = true;
                    return;
                }

                if (
                    schemaValue === 'phone' &&
                    !validatePhone(values[schemaValue])
                ) {
                    setValidationErrors(values => ({
                        ...values,
                        phone: true
                    }));
                    setValidationMessages(values => ({
                        ...values,
                        phone: i18n.validation.phone
                    }));
                    isValidationError = true;
                    return;
                }

                if (
                    schemaValue === 'multiline' &&
                    !validateMultiline(values[schemaValue])
                ) {
                    setValidationErrors(values => ({
                        ...values,
                        [schemaValue]: true
                    }));
                    setValidationMessages(values => ({
                        ...values,
                        [schemaValue]: i18n.validation.multiline
                    }));
                    isValidationError = true;
                    return;
                }

                if (
                    schemaValue === 'password' &&
                    !validatePassword(values[schemaValue])
                ) {
                    setValidationErrors(values => ({
                        ...values,
                        password: true
                    }));
                    setValidationMessages(values => ({
                        ...values,
                        password: i18n.validation.password
                    }));
                    isValidationError = true;
                    return;
                }

                if (Array.isArray(values[schemaValue])) {
                    if (!values[schemaValue].length) {
                        setValidationErrors(values => ({
                            ...values,
                            [schemaValue]: true
                        }));
                        setValidationMessages(values => ({
                            ...values,
                            [schemaValue]: i18n.validation.required
                        }));
                        isValidationError = true;
                        return;
                    }
                }

                if (!values[schemaValue]) {
                    setValidationErrors(values => ({
                        ...values,
                        [schemaValue]: true
                    }));
                    setValidationMessages(values => ({
                        ...values,
                        [schemaValue]: i18n.validation.required
                    }));
                    isValidationError = true;
                    return;
                }
            });

            return !isValidationError;
        },
        [setValidationErrors, validationSchemaValues, i18n]
    );

    const resetValidation = React.useCallback(() => {
        setValidationErrors({});
    }, []);

    const ifError = React.useCallback(
        value => {
            if (uiErrors) {
                return validationErrors[value] ? true : false;
            }
        },
        [validationErrors, uiErrors]
    );

    const errorMessages = React.useCallback(
        (value, customMessage) => {
            if (uiErrors) {
                return validationErrors[value] && customMessage
                    ? customMessage
                    : validationMessages[value];
            }
        },
        [validationErrors, uiErrors, validationMessages]
    );

    return {
        errorMessages,
        ifError,
        resetValidation,
        validateValues
    };
}
