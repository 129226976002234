import { Box, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Footer } from '../generic';
import { useLanguage } from '../hooks';
import { NavigationBar } from '../navigationBar';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
    },
    mainContent: {
        paddingTop: ({ noFooter }) => (noFooter ? '' : 50),
        width: '100%',
        flex: 1
    },
    copyrightTextContainer: {
        background: '#000',
        color: theme.palette.colors.white,
        padding: '20px 0px'
    }
}));

export default function MainLayout({ noFooter, children }) {
    const { i18n } = useLanguage();
    const classes = useStyles({ noFooter });
    return (
        <Box className={classes.root}>
            <NavigationBar />
            <Container
                maxWidth="xl"
                className={classes.mainContent}
                disableGutters={noFooter ? true : false}
            >
                {children}
            </Container>
            {noFooter ? null : <Footer />}
            <Box className={classes.copyrightTextContainer}>
                <Typography variant="body2" align="center">
                    &copy; {new Date().getFullYear()}{' '}
                    {i18n.footer.copyrightText} All rights reserved.
                </Typography>
            </Box>
        </Box>
    );
}
